<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import DynamicFormDialog from '../form/DynamicFormDialog.vue'
import type DynamicListModel from 'devcude-common/src/models/dynamic-form/DynamicListModel'
import Pager from 'devcude-common/src/models/dynamic-form/Pager'
import _ from 'lodash'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import EntityDesignDialog from '../biz/EntityDesignDialog.vue'
import DcDynamicComp from '../form/DcDynamicComp.vue'

const props = defineProps({
  queryParams: {
    type: Object,
    default: () => ({ conds: '' })
  },
  saveParams: {
    type: Object,
    default: () => ({})
  },
  entity: {
    type: String,
    default: () => ('')
  },
  dataListFlag: {
    type: String,
    default: 'LIST_MAIN'
  },
  dataFormFlagAdd: {
    type: String,
    default: 'FORM_MAIN'
  },
  dataFormFlagEdit: {
    type: String,
    default: 'FORM_MAIN'
  },
  primaryKey: {
    type: String,
    default: 'id'
  },
  parentKey: {
    type: String,
    default: 'parentId'
  }
})
const initFormData = ref(false);

const emit = defineEmits(['tableSelectionChange', 'currentRowChange'])

const router = useRouter()

let model = ref<DynamicListModel>()
let dataFormFlag = ref(props.dataFormFlagAdd)
let showDialog = ref(false)
let currentId = ref('')
let currentRow = ref({})
const searchConditionForm = ref({})
const pager = ref(new Pager())
const tableItems = ref([])
const selection = ref([])
const status = ref('LOADING')

async function loadConfig() {
  let config = await DynamicFormUtils.getListModel(props.entity, props.dataListFlag)

  if (config) {
    status.value = 'FINISH'
    model.value = config
    handleRestSearch()
  } else {
    status.value = 'ERROR'
  }

}

function handleRestSearch() {
  console.log(model.value)
  if (model.value) {
    //构造查询条件
    model.value.config.searchFields.forEach((item) => {
      searchConditionForm[item['dataEntityFieldFlag']] = item['defaultValue']
    })
    handleLoadData()
  }


}

async function handleLoadData() {

  let s: Array<string> = []

  let query = props.queryParams.conds ? props.queryParams.conds : ''
  //构造查询条件
  model.value?.config.searchFields.forEach((item) => {
    let v = searchConditionForm.value[item['dataEntityFieldFlag']]
    if (v) {
      s.push(item['dataEntityFieldFlag'] + '@' + (item['cond'] ? item['cond'] : 'eq') + '@' + v)
    }
  })

  if (s.length) {
    if (query) {
      query += ',' + s.join(',')
    } else {
      query = s.join(',')
    }
  }


  try {
    let resp = await model.value?.list(Object.assign({}, {
      conds: query,
      $list: model.value.config.flag
    }, pager.value, { onPage: false }))
    tableItems.value = DcUtils.array2Tree(resp.data.items, props.primaryKey, props.parentKey)
    _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
  } catch (err) {
    console.log(err)
  }

}

async function handleAdd(parent?) {
  initFormData.value = false;

  dataFormFlag.value = props.dataFormFlagAdd
  showDialog.value = true
  currentId.value = ''
  let ms = {};

  if(parent) {
    ms[props.parentKey] =parent[props.primaryKey]
  }

  mergeSaveParams.value = Object.assign({}, ms, props.saveParams)



}

async function handleEdit(id) {
  initFormData.value = true;
  dataFormFlag.value = props.dataFormFlagEdit
  showDialog.value = true
  currentId.value = id
}

async function handleBatchDelete() {

  if (!selection.value.length) {
    ElMessage.warning('您未选中记录')
    return
  }

  ElMessageBox.confirm('你现在正在进行批量删除操作，是否继续?')
    .then(async () => {
      debugger
      let ids = ''
      selection.value.forEach((item) => {
        if (ids) {
          ids += `,${item['id']}`
        } else {
          ids = item['id']
        }
      })
      let resp = await model.value?.batchDelete({ ids: ids })
      await handleLoadData()

    })
    .catch(() => {
      // catch error
    })
}

async function handleDelete(id) {

  ElMessageBox.confirm('你现在正在进行删除操作，是否继续?')
    .then(async () => {
      await model.value?.cfg({ showTips: true }).delete({ id })
      await handleLoadData()
    })
    .catch(() => {
      // catch error
    })

}

async function handleSelectionChange(e: any) {
  selection.value = e
  emit('tableSelectionChange', e)
}

async function handleTableOpClick(btn, row) {

  if (btn.clickType == 'route') {

    let strs: Array<string> = []
    btn.params.forEach((e) => {
      if (row[e]) {
        strs.push(`${e}=${row[e]}`)
      }
    })
    router.push(btn.url + (strs.length ? ('?' + strs.join('&')) : ''))
  }
}

loadConfig()

function handleDialogConfirm() {
  showDialog.value = false
  handleLoadData()
}

function handleRowClick(row) {
  console.log("handleRowClick")
  currentId.value = row.id
  currentRow.value = row
  handleUpdateSaveParams()
}

let mergeSaveParams = ref({})

function handleUpdateSaveParams() {

  mergeSaveParams.value = Object.assign({}, { id: currentId.value }, props.saveParams)
}

handleUpdateSaveParams()

watch(currentId, () => {
  emit('currentRowChange', currentRow.value)
})

watch(() => props.queryParams, () => {
  handleLoadData()
})

function generateAttrs(row: any, item: any): Attrs {

  let attrs = {}

  const diyCompProps = typeof item['diyCompProps'] === 'object' ? item['diyCompProps'] : JSON.parse(item['diyCompProps'] ? item['diyCompProps'] : '{}');

  let v = {}
  for (let key in row) {
    let k = `{{@row.${key}}}`
    v[k] = row[key]
  }

  if (item['diyCompProps']) {
    for (let key in diyCompProps) {
      attrs[key] = v[diyCompProps[key]] ? v[diyCompProps[key]] : diyCompProps[key]
    }
  }

  if (item['compParams']) {
    let params = item['compParams'].split(',')
    params.forEach((param) => {
      let p = param.split('@')
      console.log('dd', p)
      if (p.length == 2) {
        let k = p[0]
        let v = p[1]
        if (k === 'qp') {
          if (props.queryParams[v]) attrs[v] = props.queryParams[v]
        }
      }
    })

  }
  return attrs
}

</script>

<template>
  <div v-if="status == 'ERROR'" class="w-full h-[500px] flex justify-center bg-[#fff] items-center">
    无法加载模型列表:{{ entity }}@{{ dataListFlag }}
  </div>
  <div v-if="status == 'FINISH' " class="relative">
    <div v-if="model" class="w-full h-full">
      <dynamic-form-dialog v-if="showDialog" :save-params="mergeSaveParams" @cancel="showDialog=false"
                           @confirm="handleDialogConfirm" :show="showDialog" :entity="entity" width="auto"
                           :data-form-flag="dataFormFlag" :init-data="initFormData"></dynamic-form-dialog>
      <div v-if="!model.hideBtnGroup" class="mb-[12px] flex justify-between">
        <template v-if="model.config.showLeftBtnGroup == 'Y'">
          <el-button-group>
            <el-button @click="handleAdd()" icon="plus">新增</el-button>
            <el-button @click="handleBatchDelete()" icon="delete">删除</el-button>
          </el-button-group>
        </template>

        <template v-if="model.config.showRightBtnGroup == 'Y'">
          <div class="flex flex-1 flex-row-reverse">
            <el-button-group>
              <el-button>
                <el-icon>
                  <span class="iconfont dc-icon-daochu"></span>
                </el-icon>
              </el-button>
              <el-button>
                <el-icon>
                  <span class="iconfont dc-icon-list-setting"></span>
                </el-icon>
              </el-button>
            </el-button-group>
          </div>
        </template>


      </div>
      <el-divider v-if="model.config.searchFields?.length && !model.hideBtnGroup" class="!mt-0 !mb-[24px]" />
      <template v-if="model.config.searchFields?.length">


        <el-form :inline="true" class="mb-[6px]">

          <template v-for="field in model.config.searchFields" :key="field.dataEntityFieldFlag">
            <el-form-item v-if="field['showStatus'] == 'Y'" :label="field['name']">
              <el-input :placeholder="field['placeholder'] ? field['placeholder'] : field['name']"
                        v-model="searchConditionForm[field['dataEntityFieldFlag']]" clearable />
            </el-form-item>
          </template>

          <el-form-item>
            <el-button @click="handleLoadData" type="primary">查询</el-button>
            <el-button @click="handleRestSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
      <el-table :highlight-current-row="!!currentId" @row-click="handleRowClick" default-expand-all
                :row-style="{cursor:'pointer'}" :current-row-key="currentId" :data="tableItems"
                @selectionChange="handleSelectionChange"
                :row-key="(row:any)=>{return row.id}" border>
        <el-table-column type="selection" :reserve-selection="true"></el-table-column>

        <template v-for="item in model.config.fields" :key="item.name">
          <el-table-column v-if="item['showStatus'] == 'Y'" :label="item['name']" :prop="item['dataEntityFieldFlag']">
            <template #default="scope">
              <slot :name="item['dataEntityFieldFlag']" :row="scope.row">
                <template v-if="!item['diyComp']">
                  {{scope.row[item['dataEntityFieldFlag']]}}
                </template>

                <dc-dynamic-comp v-else v-bind:is="item['diyComp'] as string"
                                 v-model="scope.row[item['dataEntityFieldFlag']]"
                                 v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>


              </slot>

            </template>

          </el-table-column>
        </template>
        <el-table-column v-if="model.config.showTableOp == 'Y'" label="操作" align="right">
          <template #default="scope">
            <el-link v-for="(btn,index) in model.config.opBtns" :key="index" :type="btn.type ?? 'default'"
                     @click="handleTableOpClick(btn,scope.row)" :underline="false" class="mr-[12px]">{{ btn.name }}
            </el-link>
            <el-link type="primary" @click.stop="handleAdd(scope.row)" :underline="false" class="mr-[12px]">新增子级
            </el-link>
            <el-link type="primary" @click="handleEdit(scope.row.id)" :underline="false" class="mr-[12px]">编辑
            </el-link>
            <el-link :underline="false" type="danger" @click="handleDelete(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
  <entity-design-dialog v-if="model && model.config && model.config.entityId" :entity-id="model.config.entityId" init-tab="listDesign"></entity-design-dialog>



</template>

<style scoped>

</style>