import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_FILE_CATEGORY"
const routers: RouteRecordRaw = {
  path: '/file-category',
  name:'file-category',
  meta: {
    title: '附件类别管理'
  },
  children: [
    {
      path: 'list',
      name: 'file-category-list',
      meta: {
        title: '附件类别列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }
  ]
}
export default routers
