import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-supplier',
  name:'ShpSupplier',
  meta: {
    title: '供应商管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpSupplierList',
      meta: {
        title: '供应商列表'
      },
      component: ()=>{return import("@/views/shp-supplier/ShpSupplierList.vue")}
    },
    {
      path: 'add',
      name: 'ShpSupplierAdd',
      meta: {
        title: '供应商新增'
      },
      component: ()=>{return import("@/views/shp-supplier/ShpSupplierAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpSupplierEdit',
      meta: {
        title: '供应商编辑'
      },
      component: ()=>{return import("@/views/shp-supplier/ShpSupplierEdit.vue")}
    }
  ]
}
export default routers
