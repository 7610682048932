<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps(['entity', 'show', 'title', 'width','queryParams','dataListFlag'])
const emit = defineEmits(['confirm', 'cancel'])

const selection = ref([])

function handelSelectChange(e) {
  selection.value = e
}
console.log("gggg",props.queryParams)
</script>

<template>
  <el-dialog
    v-if="props.show"
   :model-value="props.show"
    :title="props.title"
    :width="props.width"
  >
    <common-list v-if="show"  :query-params="queryParams" class="!p-0" :entity="entity" :data-list-flag="dataListFlag" @table-selection-change="handelSelectChange"></common-list>
    <template #footer>
      <div class="flex justify-center p-[24px]">
        <el-button size="large" type="primary" @click="emit('confirm',selection)">确定</el-button>
        <el-button size="large" @click="emit('cancel')">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>