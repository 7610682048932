export default class {

  static $name:string="@DC_DATA_FORM_FIELD"
    //ID
    id?:number;
    //版本号
    version?:number;
    //逻辑删除标识
    valid?:number;
    //创建时间
    createTime?:date;
    //最后修改时间
    updateTime?:date;
    //数据表单ID
    dataFormId?:number;
    //数据实体标识
    entityFlag?:string;
    //数据表单标识
    dataFormFlag?:string;
    //类型
    type?:string;
    //表单字段标识
    dataEntityFieldFlag?:string;
    //字段名称
    name?:string;
    //默认值
    defaultValue?:string;
    //是否显示
    showStatus?:string;
    //排序
    sortNum?:number;
    //是否必填
    isRequire?:string;
    //是否禁用
    isDisable?:string;
    //拓展字段
    expend?:string;
    //输入提示
    placeholder?:string;
    //表单组件
    formComp?:string;
    //组件参数
    formCompProps?:string;

}