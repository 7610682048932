import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_SYS_USER"
const routers: RouteRecordRaw = {
  path: '/sys-user',
  name:'sys-user',
  meta: {
    title: '系统用户管理'
  },
  children: [

    {
      path: 'list',
      name: 'sys-user-list',
      meta: {
        title: '系统用户列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }

  ]
}
export default routers
