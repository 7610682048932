<script setup lang="ts">
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

const modeValue = defineModel<String>()
const props = defineProps({
  placeholder: {
    type: String,
    default: '请输入内容...'
  },
  mode: {
    type: String,
    default: 'default'
  },
  minHeight: {
    type: String,
    default: '300px'
  }
})
const editorRef = shallowRef()
const toolbarConfig = {}
const editorConfig = { placeholder: props.placeholder }

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleCreated = (editor: any) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}


</script>

<template>
  <div class="dc-editor">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      v-model="modeValue"
      :defaultConfig="editorConfig"
      :mode="mode"
      :style="{height:minHeight}"
      @onCreated="handleCreated"
    />

  </div>

</template>

<style>
.dc-editor {
  border: 1px solid #ccc;
  position: relative;
  line-height: initial;
}


</style>