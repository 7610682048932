<script setup lang="ts">
import TableListForm from '../../../../src/components/dynamic-form/TableListForm.vue'
import DynamicListDialog from '../../../../src/components/form/DynamicListDialog.vue'
import { computed, ref, watch } from 'vue'
import DataEntityField from '../../../../data-entity/DataEntityField'
import type TableField from '../../../../data-entity/TableField'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import TableFieldSelect from '../../../../src/components/form/TableFieldSelect.vue'
const props = defineProps(['entityObj'])
const showDialog = ref(false);
const form = ref();

function handleConfirm(items:TableField[]){


  items.forEach((item)=>{
    let field = new DataEntityField()
    field.name = item.remark;
    field.flag = DcUtils.toCamelCase(item.name as string);
    field.type = DcUtils.mysqlType2DataType(item.type as string);
    field.tableFieldName = item.name;
    field.primaryKey = item.primaryKey;
    form.value.handleAdd(field);
  });
  showDialog.value = false;
}

const params =  computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({entityFlag:props.entityObj.flag,entityId:props.entityObj.id})}});
const saveParams =  computed(()=>{return {entityFlag:props.entityObj.flag,entityId:props.entityObj.id}});
const queryParams =  computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({tableName:props.entityObj.tableName,dsFlag:props.entityObj.dsFlag})}});




const underLine2Case = ref(true);


</script>

<template>
  <div class="mb-[12px] flex items-center">
    <el-button @click="showDialog=true" type="primary" class="mr-[24px]">批量导入</el-button>
    <el-checkbox v-model="underLine2Case" label="开启下划线转驼峰" true-value="true" false-value="false" />
  </div>
  <dynamic-list-dialog @confirm="handleConfirm" @cancel="showDialog=false"  :show="showDialog" :query-params="queryParams" entity="DC_TABLE_FIELD"></dynamic-list-dialog>
  <table-list-form  ref="form" :query-params="params" :save-params="saveParams" entity="DC_DATA_ENTITY_FIELD">
      <template v-slot:tableFieldName="scope">
        <table-field-select  :ds-flag="entityObj.dsFlag" :table-name="entityObj.tableName" v-model="scope.row.tableFieldName"></table-field-select>
      </template>
  </table-list-form>
</template>

<style scoped>

</style>