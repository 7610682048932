<script setup lang="ts">
import TableFieldApi from '../../../api/TableFieldApi'
import { ref, watch } from 'vue'
import TableField from '../../../api/TableFieldApi'
const props = defineProps(['dsFlag', 'tableName'])
const items = ref<Array<TableField>>([])
async function handleLoadData() {
  if (!props.dsFlag || !props.tableName) {
    return
  }

  const cacheKey = ('TABLE_FIELD_SELECT_CACHE@' + props.dsFlag + "_" + props.tableName);
  if(!window[cacheKey]){
    window[cacheKey] =  TableFieldApi.list(props, { onPage: false })
  }

  window[cacheKey].then(resp=>{
    if (resp.status === 200) {
      items.value = resp.data.items
    }
  })


}

watch(()=>props, () => {
  handleLoadData()
}, { immediate: true })


</script>

<template>
  <el-select filterable>
    <el-option
      v-for="item in items"
      :key="item['name']"
      :label="`${item['name']}[${item['remark']}]`"
      :value="item['name']">
    </el-option>
  </el-select>
</template>

<style scoped>

</style>