import { createRouter, createWebHistory} from 'vue-router'
import HomeView from 'devcude-ui/src/views/HomeView.vue'
const modules =  import.meta.glob('./modules/*.ts', { eager: true })
const routes = {
  path: '/',
  name: 'ShpHome',
  meta:{
    title:'首页'
  },
  component: HomeView,
  children:[]
}

for (const path in modules) {
  routes.children.push(modules[path].default)
}
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [routes]
})

console.log(router.getRoutes())


export default router
