<script setup lang="ts">
import { ref } from 'vue'
import qs from 'qs'
import ShpProdCardList from '@/components/shp-prod-choose-box/ShpProdCardList.vue'
import ShpProdSkuChooseBox from '@/components/shp-prod-choose-box/ShpProdSkuChooseBox.vue'
import AmountText from '@/components/AmountText.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import ProdItemDescList from '@/components/ProdItemDescList.vue'

const emit = defineEmits(['skuConfirm'])
const props = defineProps({
  mode: {
    type: String,
    default: 'orderItem'
  }
})
const dialogVisible = ref(false)
const pager = ref({
  pageIndex: 1,
  pageSize: 10,
  itemCount: 0
})
const skuChooseBox = ref(null)
const orderItems = defineModel([])

async function handleProdClick(item) {
  skuChooseBox.value.handleShow(item.id, (orderItem) => {
    if (props.mode === 'orderItem') {
      orderItems.value.push(orderItem)
    }

    if (props.mode === 'sku') {
      emit('skuConfirm', orderItem)
      close()
    }
  })
}

async function handleChooseOrderItem(item, index) {
  skuChooseBox.value.handleShowOrderItem(JSON.parse(JSON.stringify(item)), (orderItem) => {
    orderItems.value[index] = orderItem
  })
}

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

async function show() {
  dialogVisible.value = true
}

async function close() {
  dialogVisible.value = false
}

defineExpose({ show })

</script>

<template>
  <el-dialog append-to-body v-model="dialogVisible" :width="mode === 'orderItem' ? '1240px' : '872px'"
             class="!p-0 shp-prod-choose-box"
             :show-close="false">
    <template #header></template>
    <div class="flex justify-between overflow-hidden h-[838px]">
      <div v-if="mode === 'orderItem'" class="border-r w-[368px] flex flex-col">
        <div class="flex justify-between  h-[56px] items-center border-b pl-[12px] pr-[12px]">
          <div>
            <el-text>已选列表</el-text>
          </div>
          <div>
            <el-text class="cursor-pointer" @click="orderItems=[]">清空</el-text>
          </div>
        </div>
        <div class="flex-1 overflow-hidden">

          <el-empty class="h-full" v-if="!orderItems?.length"></el-empty>

          <el-scrollbar class="h-full">
            <div class="p-[12px]">
              <div v-for="(orderItem,index) in orderItems" @click="handleChooseOrderItem(orderItem,index)"
                   class="order-item mb-[12px] cursor-pointer">
                <div class="bg-[#E6E8EB] flex justify-between p-[12px] text-[#606266]">
                  <div class="flex-1">
                    <el-text size="default">{{ orderItem.name }}</el-text>
                  </div>
                  <div class="w-[38px] text-right">
                    <el-text size="default">x{{ orderItem.itemQty }}</el-text>
                  </div>
                  <div class="w-[80px] text-right">
                    <el-text size="default">
                      <amount-text :amount="orderItem.price"></amount-text>
                    </el-text>
                  </div>

                  <div class="w-[36px] text-right">
                    <el-text type="danger" @click.stop="orderItems.splice(orderItems.indexOf(orderItem),1)"
                             class="cursor-pointer"
                             size="small">
                      删除
                    </el-text>
                  </div>
                </div>
                <div class="border p-[12px]">
                  <div>
                    <template v-for="item in qsParse(orderItem.skuCombo)">
                      <span class="mr-[12px]">
                          <el-text size="small">
                        {{ item.name }} : {{ item.value }}
                      </el-text>
                      </span>
                    </template>
                  </div>
                  <div>
                    <div>
                      <div>
                        <el-text size="small">套餐必选</el-text>
                      </div>
                      <ProdItemDescList v-model="orderItem.pkgMustItems"></ProdItemDescList>
                    </div>
                    <div v-for="group in orderItem.pkgCustItems">
                      <div>
                        <el-text size="small">{{ group.name }}({{ group.requiredQty }})</el-text>
                      </div>
                      <ProdItemDescList :model-value="group.items.filter(e=>e.choosed)"></ProdItemDescList>
                    </div>
                    <div>
                      <el-text size="small">
                        备注:{{ orderItem.remarks ? orderItem.remarks : '(无)' }}
                      </el-text>
                    </div>
                  </div>
                  <div class="flex flex-row-reverse">
                    <el-text type="warning" size="default">
                      小计:
                      <amount-text :amount="orderItem.price * orderItem.itemQty"></amount-text>
                    </el-text>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="flex flex-row-reverse justify-center p-[12px] border-t">
          <el-button @click="close" size="default">取消</el-button>
          <el-button @click="close" class="mr-[12px]" size="default" type="primary">确认</el-button>
        </div>
      </div>
      <ShpProdCardList class="w-[872px]" @item-click="handleProdClick"></ShpProdCardList>
      <ShpProdSkuChooseBox :mode="mode" ref="skuChooseBox"></ShpProdSkuChooseBox>
    </div>
  </el-dialog>
</template>

<style>
.shp-prod-choose-box .el-dialog__header {
  display: none;
}
</style>