import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class DataEntityApi {

    static  async  add(dataEntityParam:{
           name?:string
           flag?:string
           dsFlag?:string
           tableName?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityParam
        ))


  const resp = await $http.post("/api/dataEntity/add",$_params,config);
  return resp;
  }
    static  async  detail(dataEntityParam:{
           name?:string
           flag?:string
           dsFlag?:string
           tableName?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityParam
        ))


  const resp = await $http.post("/api/dataEntity/detail",$_params,config);
  return resp;
  }
    static  async  batchDelete(ids:{
           ids?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ids
        ))


  const resp = await $http.post("/api/dataEntity/batchDelete",$_params,config);
  return resp;
  }
    static  async  list(dataEntityParam:{
           name?:string
           flag?:string
           dsFlag?:string
           tableName?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
pagerBean:{
           pageIndex?:any
           pageSize?:any
           isFirst?:boolean
           isLast?:boolean
           itemCount?:any
           onPage?:boolean
           pageCount?:any
           orders?:any
           conds?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityParam
            ,
        pagerBean
        ))


  const resp = await $http.post("/api/dataEntity/list",$_params,config);
  return resp;
  }
    static  async  delete(dataEntityParam:{
           name?:string
           flag?:string
           dsFlag?:string
           tableName?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityParam
        ))


  const resp = await $http.post("/api/dataEntity/delete",$_params,config);
  return resp;
  }
    static  async  update(dataEntityParam:{
           name?:string
           flag?:string
           dsFlag?:string
           tableName?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityParam
        ))


  const resp = await $http.post("/api/dataEntity/update",$_params,config);
  return resp;
  }

}