<script setup lang="ts">
import { ref } from 'vue'
import DictCompCommonApi from '../../../api/DictCompCommonApi'
import type DictTypeItem from '../../../data-entity/DictTypeItem'

const props = defineProps({
  dictType: { type: String, default: '' }
})

const v = defineModel<String>([])

const options = ref<Array<DictTypeItem>>()

async function initData() {

  const cacheKey = ('DC_DICT_SELECT_CACHE')
  if (!window[cacheKey]) {
    window[cacheKey] = DictCompCommonApi.dictData()
  }

  window[cacheKey].then(resp => {
    options.value = resp.data.items.filter(e => e.dictTypeFlag === props.dictType)
  })

}



initData()

</script>

<template>
  <div class="dynamic-check-box">
    <el-radio-group v-model="v">
      <el-radio v-for="item in options" :value="item.dictValue" :label="item.name">{{item.name}}</el-radio>
    </el-radio-group>
  </div>
</template>

<style scoped>

</style>