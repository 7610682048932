<script setup lang="ts">
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { computed } from 'vue'

const props = defineProps({
  limit: {
    type: Number,
    default() {
      return -1
    }
  }
})

const v = defineModel([])

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

const chooseIds = computed(() => {
  let ids = []
  v.value?.forEach((e) => {
    ids.push(e.id)
  })
  return ids
})


function handleChoose(item) {
  if (!item.choosed && props.limit != -1) {

    if (v.value.filter(e => e.choosed).length >= props.limit) {
      if (props.limit == 1) {
        v.value.forEach((e) => {
          e.choosed = false
        })
      } else {
        return
      }

    }
  }
  item.choosed = !item.choosed
}

</script>

<template>
  <div>
    <div v-for="item in v" class="flex border  mb-[12px] pt-0 pb-0 justify-between cursor-pointer"
         @click="handleChoose(item)" :class="{'item-choose':item.choosed}">
      <div class="flex items-center border-r">
        <DcSinglePicShow width="66px" height="66px" :model-value="item.pic"></DcSinglePicShow>
      </div>
      <div class="flex flex-col  flex-1 pl-[12px]">
        <div>
          <el-text size="small" class="!mr-[12px]">{{ item.name }}</el-text>
        </div>
        <div>
          <el-text size="small" class="!mr-[12px]">编号:{{ item.productNum }}</el-text>
          <el-text size="small" class="!mr-[12px]">数量:{{ item.itemQty }}</el-text>
          <el-text size="small" class="!mr-[12px]">分类{{ item.categoryName }}</el-text>
          <el-text size="small">规格:
            <template v-for="ic in qsParse(item.skuCombo)">
                      <span class="mr-[12px]">
                          <el-text size="small">{{ ic.name }} : {{ ic.value }}</el-text>
                      </span>
            </template>
            <template v-if="!qsParse(item.skuCombo).length">
              <el-text size="small">常规</el-text>
            </template>
          </el-text>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-choose {
  background-color: #409EFF;
  color: #fff!important;
  @apply border-[#409EFF]
}

.item-choose .el-text{
  color: #fff!important;

}

</style>