export default class {

  static $name:string="@DC_DATA_ENTITY_FIELD"
    //ID
    id?:number;
    //版本号
    version?:number;
    //逻辑删除标识
    valid?:number;
    //创建时间
    createTime?:date;
    //最后修改时间
    updateTime?:date;
    //数据实体ID
    entityId?:number;
    //数据实体标识
    entityFlag?:string;
    //数据实体字段名
    name?:string;
    //数据实体字段标识
    flag?:string;
    //管理数据标字段标识
    tableFieldName?:string;
    //排序
    sort?:number;
    //默认值
    defaultValue?:string;
    //字段类型
    type?:string;
    //是否主键: 是(Y)，否(N)
    primaryKey?:string;
    //排序
    sortNum?:number;

}