import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_SYS_ROUTE_SETTING"
const routers: RouteRecordRaw = {
  path: '/sys-route-settingdddd',
  name:'sys-route-setting',
  meta: {
    title: '系统路由管理'
  },
  children: [

    {
      path: 'list',
      name: 'sys-route-setting-list',
      meta: {
        title: '系统路由列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }

  ]
}
export default routers
