import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class TableFieldApi {

    static  async  update(tableFieldParam:{
           dsFlag?:string
           tableName?:string
           type?:string
           name?:string
           remark?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        tableFieldParam
        ))


  const resp = await $http.post("/api/tableField/update",$_params,config);
  return resp;
  }
    static  async  batchDelete(ids:{
           ids?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ids
        ))


  const resp = await $http.post("/api/tableField/batchDelete",$_params,config);
  return resp;
  }
    static  async  detail(tableFieldParam:{
           dsFlag?:string
           tableName?:string
           type?:string
           name?:string
           remark?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        tableFieldParam
        ))


  const resp = await $http.post("/api/tableField/detail",$_params,config);
  return resp;
  }
    static  async  list(tableFieldParam:{
           dsFlag?:string
           tableName?:string
           type?:string
           name?:string
           remark?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
pagerBean:{
           pageIndex?:any
           pageSize?:any
           isFirst?:boolean
           isLast?:boolean
           itemCount?:any
           onPage?:boolean
           pageCount?:any
           orders?:any
           conds?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        tableFieldParam
            ,
        pagerBean
        ))


  const resp = await $http.post("/api/tableField/list",$_params,config);
  return resp;
  }
    static  async  add(tableFieldParam:{
           dsFlag?:string
           tableName?:string
           type?:string
           name?:string
           remark?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        tableFieldParam
        ))


  const resp = await $http.post("/api/tableField/add",$_params,config);
  return resp;
  }
    static  async  delete(tableFieldParam:{
           dsFlag?:string
           tableName?:string
           type?:string
           name?:string
           remark?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        tableFieldParam
        ))


  const resp = await $http.post("/api/tableField/delete",$_params,config);
  return resp;
  }

}