import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_FILE_SERVER"
const routers: RouteRecordRaw = {
  path: '/file-server',
  name:'file-server',
  meta: {
    title: '附件服务器'
  },
  children: [
    {
      path: 'list',
      name: 'file-server-list',
      meta: {
        title: '附件服务器列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }
  ]
}
export default routers
