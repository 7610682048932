<script setup lang="ts">
import { computed, ref, watch } from 'vue'

const props = defineProps({
  btnType: {
    type: String,
    default: 'primary'
  },
  btnText: {
    type: String,
    default: '请选择文件'
  },
  width: {
    type: String,
    default: '100px'
  },
  height: {
    type: String,
    default: '100px'
  },
  fit: {
    type: String,
    default: 'cover'
  }
})
const fileCodes = defineModel();
const fileUrls = computed(() => {
  console.log(fileCodes)
  let urls = []
  let arr = fileCodes.value?.split(',')
  if (arr?.length) {
    arr.forEach((e) => {
      urls.push(`/api/nasServer/access/${e}`)
    })
  }
  return urls
})


const dialogImageUrl = ref('')
const dialogVisible = ref(false)


const handlePictureCardPreview = (fileUrl) => {
  dialogImageUrl.value = fileUrl
  dialogVisible.value = true
}

</script>

<template>
  <div class="picture-list">
    <el-image
      class="mr-[12px] border cursor-pointer"
      v-for="fileUrl in fileUrls"
      @click="handlePictureCardPreview(fileUrl)"
      :src="fileUrl"
      :style="{width:width,height:height}"
      :fit="fit"></el-image>
  </div>
  <el-dialog z-index="999999" append-to-body v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
</template>

<style scoped>


</style>
