<script setup lang="ts">
import TableListForm from '../../../../src/components/dynamic-form/TableListForm.vue'
import DynamicListDialog from '../../../../src/components/form/DynamicListDialog.vue'
import { computed, ref } from 'vue'
import DataEntityField from '../../../../data-entity/DataEntityField'
import DataListField from '../../../../data-entity/DataListField'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DataEntity from '../../../../data-entity/DataEntity'
const props = defineProps(['queryParams','saveParams','entityObj'])

const showDialog = ref(false);
const form = ref(null);

function handleConfirm(items:DataEntityField[]){

  items.forEach((item)=>{
    let field = new DataListField()
    field.name = item.name
    field.dataEntityFieldFlag = item.flag
    field.type =item.type
    field.defaultValue = item.defaultValue
    field.sortNum = 0;
    field.showStatus = 'Y';
    form.value?.handleAdd(field);
  });
  showDialog.value = false;
}

const dataEntityQueryParams = computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({entityId:props.entityObj.id,entityFlag:props.entityObj.flag})}})
</script>

<template>
  <div>
    <dynamic-list-dialog @close="showDialog=false"  @confirm="handleConfirm" @cancel="showDialog=false" :show="showDialog" :query-params="dataEntityQueryParams" entity="DC_DATA_ENTITY_FIELD"></dynamic-list-dialog>
    <div class="mb-[12px]">
      <el-button @click="showDialog=true" type="primary">批量导入</el-button>
    </div>
    <div class="flex flex-col w-full">
      <table-list-form ref="form" :query-params="queryParams" :save-params="saveParams" entity="DC_DATA_LIST_FIELD"></table-list-form>
    </div>
  </div>
</template>

<style scoped>

</style>