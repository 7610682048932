<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import Sortable from 'sortablejs'
import type DynamicTableFormModel from 'devcude-common/src/models/dynamic-form/DynamicTableFormModel'
import Pager from 'devcude-common/src/models/dynamic-form/Pager'
import type DataListField from '../../../data-entity/DataListField'
import DcDynamicComp from '../form/DcDynamicComp.vue'
import EntityDesignDialog from '../biz/EntityDesignDialog.vue'

const props = defineProps({
  queryParams: {
    type: Object
  },
  saveParams: {
    type: Object
  },
  size: {
    type: String
  },
  entity: {
    type: String
  },
  dataFormFlag: {
    type: String,
    default: 'FORM_TABLE'
  },
  treeParentKey: {
    type: String
  }

})


const emit = defineEmits(['tableSelectionChange', 'currentRowChange'])

let model = ref<DynamicTableFormModel>()
const init = ref(false)
let tableItems = ref<Array<any>>([])
let pager = ref(new Pager())
const selection = ref([])

async function loadConfig() {
  const m = await DynamicFormUtils.getTableFormModel(props.entity as string, props.dataFormFlag)
  if (m) {
    model.value = m
    init.value = true
    handleLoadData()
  }

}

let currentId = ref('')
let currentRow = ref({})
let tableForm = ref(null)

let roots = computed(() => {
  let tree = arrayToTree(tableItems.value)
  tree.sort((a, b) => {
    // 将空值（null 或 undefined）排在最后
    if (!a.sortNum && !b.sortNum) {
      return 1
    }
    if (!a.sortNum) return 1
    if (!b.sortNum) return 1

    // 按 sortNum 值升序排序
    return a.sortNum - b.sortNum
  })
  return tree
})

// 树形结构转换为数组的方法
function treeToArray(treeNodes) {
  // 初始化结果数组
  let result = []

  // 递归遍历树
  function traverse(node) {
    // 将当前节点的值添加到结果数组
    result.push(node)

    // 遍历子节点
    for (let child of node.children) {
      traverse(child)
    }
  }

  // 开始遍历树
  treeNodes.forEach((treeNode) => {
    traverse(treeNode)

  })

  // 返回结果数组
  return result
}


let sortItems = computed(() => {
  return treeToArray(roots.value)
})

function arrayToTree(array) {
  const map = new Map<number, any>()
  const roots = []
  array.forEach(item => {
    item.children = [] // 初始化 children 属性
    map.set(item.id, item)
  })


  array.forEach(item => {
    if (!item.parentId) {
      roots.push(item) // 没有父节点，说明是根节点
    } else {

      const parent = map.get(item.parentId)
      if (parent) {
        parent.children?.push(item) // 将当前项添加到父节点的 children 属性中
      }
      parent?.children?.sort((a, b) => {
        // 将空值（null 或 undefined）排在最后
        if (!a.sortNum && !b.sortNum) {
          return 1
        }
        if (!a.sortNum) return 1
        if (!b.sortNum) return 1

        // 按 sortNum 值升序排序
        return a.sortNum - b.sortNum
      })
    }
  })
  return roots
}


async function handleLoadData() {

  if (!model.value || typeof model.value.list !== 'function') {
    return
  }

  let p = Object.assign({      $list: model.value.config.flag
  }, props.queryParams ? props.queryParams : {}, model.value.pager, {
    onPage: false,
    orders: model.value.config?.sort
  })
  const resp = await model.value?.list(p)
  resp.data.items.forEach((item) => {
    item._$tableId = uuidv4()
  })


  tableItems.value = resp.data.items


  pager.value = Object.assign(pager.value)
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
  nextTick(() => {
    handleInitSortable()
  })

}


async function handleSelectionChange(e: any) {
  selection.value = e
  emit('tableSelectionChange', e)
}


function handleRowClick(row) {
  currentId.value = row.id
  currentRow.value = row
}

let mergeSaveParams = ref(Object.assign({}, { id: currentId.value }, props.saveParams))

watch(currentId, () => {
  mergeSaveParams.value = Object.assign({}, { id: currentId.value }, props.saveParams)
  emit('currentRowChange', currentRow.value)
})

watch(() => props.queryParams, (v) => {
  handleLoadData()
})


function handleAdd(data?, parent?) {
  let item = {}
  model.value?.config.fields.forEach((e: DataListField) => {
    if (e.dataEntityFieldFlag) item[e.dataEntityFieldFlag] = (data && data[e.dataEntityFieldFlag]) ? data[e.dataEntityFieldFlag] : e.defaultValue
  })
  item['_$tableId'] = uuidv4()
  if (parent?.id) {
    item['parentId'] = parent['id']
  }

  tableItems.value.push(item)
  unSaveRows.value.push(item)
  nextTick(() => {
    if (parent) tableForm.value.toggleRowExpansion(parent, true)
  })

}

async function handleDelete(row: any) {
  if (row.id) {
    let resp = await model.value?.delete({ id: row.id })
    if (resp.status == 200) {
      tableItems.value.splice(tableItems.value.indexOf(row), 1)
    }
  } else {
    tableItems.value.splice(tableItems.value.indexOf(row), 1)
  }
}

const unSaveRows = ref<Array<any>>([])

function handleRecordUnSaveRow(row) {
  if (unSaveRows.value.indexOf(row) == -1) unSaveRows.value.push(row)
}

async function handleSave(row, del: boolean = true) {
  let resp
  try {
    if (row.id) {
      resp = await model.value?.update({$form:props.dataFormFlag}, row, props.saveParams)
    } else {
      resp = await model.value?.add({$form:props.dataFormFlag}, row, props.saveParams)
    }
    if (resp.status == 200) {  // 更广泛的成功状态码检查
      if (!row.id) row.id = resp.data.id
      if (del) unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)
      return row
    } else {
      console.error(`Save failed with status: ${resp.status}`)
    }
  } catch (error) {
    console.error('Error saving row:', error)
  }
  return null
}

async function handleBatchSave() {
  let rowsToDelete: Array<any> = []


  try {
    for (let i = 0; i < unSaveRows.value.length; i++) {
      const savedRow = await handleSave(unSaveRows.value[i], false)
      if (savedRow) {
        rowsToDelete.push(savedRow)
      }
    }
    rowsToDelete.forEach(row => {
      const index = unSaveRows.value.indexOf(row)
      if (index !== -1) {
        unSaveRows.value.splice(index, 1)
      }
    })
  } catch (error) {
    console.error('Batch save failed:', error)
  }
}

let { ctx: vm, proxy } = getCurrentInstance()

function handleInitSortable() {
// 获取el-table DOM
  if (!tableForm.value || !tableForm.value['$el']) {
    return
  }
  let tbody = tableForm.value['$el'].querySelectorAll('tbody')[0]

  if (!tbody) {
    return
  }
  //
  Sortable.create(tbody as HTMLElement, {
    animation: 150,
    ghostClass: 'blue-background-class',
    handle: '.drag-btn', // 如果需要点击某个图标拖拽的话需要吧那个图标的class写在这里
    // 写完以上部分就已经可以实现基本的拖拽功能了，以下是拓展
    //始拖拽事件
    onEnd: function(/**Event*/evt) {
      let tmp = Object.assign([], sortItems.value)
      const movedItem = tmp.splice(evt.oldIndex as number, 1)[0]
      tmp.splice(evt.newIndex as number, 0, movedItem)


      let i = tmp.indexOf(movedItem)

      if (i == 0 || i == (tmp.length - 1)) {
        movedItem.parentId = ''
      }else{
        if(movedItem.parentId == tmp[i+1].id){
          movedItem.parentId = tmp[i+1].parentId
        }else{
          movedItem.parentId = tmp[i-1].id
        }
      }

      console.log(evt.newIndex, i)

      tmp.forEach((e, i) => {
        e['sortNum'] = i
      })
      tableItems.value = tmp
      tableItems.value.forEach((e) => {
        handleRecordUnSaveRow(e)
      })
    },
    // Event when you move an item in the list or between lists
    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
      console.log(evt,originalEvent)
      // // Example: https://jsbin.com/nawahef/edit?js,output
      // evt.dragged; // dragged HTMLElement
      // evt.draggedRect; // DOMRect {left, top, right, bottom}
      // evt.related; // HTMLElement on which have guided
      // evt.relatedRect; // DOMRect
      // evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
      // originalEvent.clientY; // mouse position
      // // return false; — for cancel
      // // return -1; — insert before target
      // // return 1; — insert after target
      // // return true; — keep default insertion point based on the direction
      // // return void; — keep default insertion point based on the direction
    },
  })
}


onMounted(() => {
  handleInitSortable()
})


loadConfig()

defineExpose({ handleAdd })

interface Attrs {
  placeholder: string,
  disabled: boolean,
  onChange: (event: Event) => void,
  onInput: (event: Event) => void
}

function generateAttrs(row: any, item: any): Attrs {
  let attrs = {
    placeholder: item.placeholder || item.name,
    disabled: item.isDisable === 'Y',
    onChange: (event: Event) => handleRecordUnSaveRow(row),
    onInput: (event: Event) => handleRecordUnSaveRow(row)
  }

  const formCompProps = typeof item['formCompProps'] === 'object' ? item['formCompProps'] : JSON.parse(item['formCompProps'] ? item['formCompProps'] : '{}');

  let v = {}
  for (let key in row) {
    let k = `{{@row.${key}}}`
    v[k] = row[key]
  }

  for (let key in props.queryParams) {
    let k = `{{@qp.${key}}}`
    v[k] = props.queryParams[key]
  }



  if (item['formCompProps']) {
    for (let key in formCompProps) {
      attrs[key] = v[formCompProps[key]] ? v[formCompProps[key]] : formCompProps[key]
    }
  }

  return attrs;

}

</script>

<template>
  <div v-if="init" class="w-full h-full flex flex-col relative">
    <div class="mb-[16px]">
      <el-button @click="handleAdd()">新增一行</el-button>
      <el-button @click="handleBatchSave()">批量保存</el-button>
    </div>
    <div class="flex-1  overflow-hidden">
      <div class="h-full max-h-full">
        <el-table
          height="100%"
          current-row-key="_$tableId"
          ref="tableForm" :size="size" :highlight-current-row="!!currentId" @row-click="handleRowClick"
          :row-style="{cursor:'pointer'}" :current-row-key="currentId" :data="roots"
          @selectionChange="handleSelectionChange"
          :row-key="(row:any)=>{return row._$tableId}" border>

          <el-table-column label="" width="120px" align="left">

          </el-table-column>


          <template v-for="item in model?.config?.fields" :key="item.name">

            <el-table-column min-width="200px" v-if="item['showStatus'] == 'Y' && item['expend'] != 'Y'"
                             :label="item['name']"
                             :prop="item['dataEntityFieldFlag']">


              <template #default="scope">
                <slot :name="item['dataEntityFieldFlag']" :row="scope.row">
              <span>
                <el-form-item class="!mb-0">
                <el-input v-if="!item['formComp']" :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                          :disabled="item['isDisable'] == 'Y'" @change="handleRecordUnSaveRow(scope.row)"
                          @input="handleRecordUnSaveRow(scope.row)"
                          v-model="scope.row[item['dataEntityFieldFlag']]"></el-input>

                <dc-dynamic-comp v-else v-bind:is="item['formComp'] as string" v-model="scope.row[item['dataEntityFieldFlag']]"
                           v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>

              </el-form-item>
              </span>
                </slot>

              </template>


            </el-table-column>
          </template>
          <el-table-column width="220px" label="操作" align="right" fixed="right">
            <template #default="scope">
              <el-link @click="handleSave(scope.row)" type="primary" :disabled="unSaveRows.indexOf(scope.row) == -1"
                       :underline="false" class="mr-[12px]">保存
              </el-link>
              <el-link class="mr-[12px]" :disabled="unSaveRows.indexOf(scope.row) != -1"
                       @click="handleAdd(null,scope.row)" :underline="false"
                       type="primary">新增子节点
              </el-link>
              <el-link class="mr-[12px]" @click="handleDelete(scope.row)" :underline="false" type="danger">删除
              </el-link>
              <el-link class="drag-btn" :underline="false" type="info">排序</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <entity-design-dialog v-if="model && model.config && model.config.entityId" :entity-id="model.config.entityId" init-tab="formDesign"></entity-design-dialog>

  </div>
  <div v-else class="w-full h-[500px] flex justify-center bg-[#fff] items-center">
    无法加载模型列表表单:{{ entity }}@{{ dataFormFlag }}
  </div>
</template>

<style scoped>

</style>