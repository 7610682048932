<script setup lang="ts">
import { computed, ref } from 'vue'
import DynamicFormCommonApi from '../../../api/DynamicFormCommonApi'

const props = defineProps(['is',])
const cacheKey = ('DC_DIY_COMP_SELECT')
const diyComps = ref([])

const comp = computed(() => {
  let comp = diyComps.value.find(e => e['flag'] === props.is)
  if (!comp) {
    return null
  }
  return comp
})


const attrs = computed(() => {
  let attrs = {}

  const defaultProps = typeof comp?.value['props'] === 'object' ? comp.value['props'] : JSON.parse(comp.value['props'] ? comp.value['props'] : '{}');

  if (defaultProps) {
    for (let key in defaultProps) {
      attrs[key] = defaultProps[key]
    }
  }

  return attrs
})





if (!window[cacheKey]) {
  window[cacheKey] = DynamicFormCommonApi.diyComps()
}

window[cacheKey].then((resp) => {
  diyComps.value = resp.data.items
})

</script>

<template>
  <component  v-if="comp" :is="comp['label']" v-bind="attrs"></component>

  <div class="text-[10px]" v-else> 无法加载自定义组件:DcDynamicComp@{{ is }}</div>
</template>

<style scoped>

</style>