<script setup lang="ts">
import DataFormFieldApi from '../../../api/DataFormFieldApi'
import DataFormField from '../../../data-entity/DataFormField'
import { ref, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'

const props = defineProps(['conds'])
const items = ref<Array<DataFormField>>([])

async function handleLoadData() {

  if (!props.conds) {
    return
  }

  let params = DcUtils.parseDynamicCondQuery(props.conds)
  let dataFormId = params['dataFormId']
  console.log("dataFormId",dataFormId,params)
  if (!dataFormId) {
    return
  }
  const cacheKey = ('TABLE_FIELD_SELECT_CACHE@' + dataFormId)
  let condQuery =DcUtils.param2DynamicCondQuery({dataFormId:dataFormId});
  if (!window[cacheKey]) {
    window[cacheKey] = DataFormFieldApi.list({}, { onPage: false,conds:condQuery })
  }

  window[cacheKey].then(resp => {
    if (resp.status === 200) {
      items.value = resp.data.items
    }
  })


}

watch(() => props, () => {
  handleLoadData()
}, { immediate: true })


</script>

<template>
  <el-select filterable>
    <el-option
      v-for="item in items"
      :key="item['dataEntityFieldFlag']"
      :label="`${item['dataEntityFieldFlag']}[${item['name']}]`"
      :value="item['dataEntityFieldFlag'] as string">
    </el-option>
  </el-select>
</template>

<style scoped>

</style>