import  DataModel from '../../models/dynamic-form/DataModel'
import  Pager from '../../models/dynamic-form/Pager'
import  FieldConfig from '../../models/dynamic-form/FieldConfig'
import BaseModel from '../../models/dynamic-form/BaseModel'
import type BtnConfig from '@/models/dynamic-form/BtnConfig'

export default class DynamicTableFormModel extends BaseModel{


  formItems:Array<FieldConfig> = []

  formData:any = {}

  pager?:Pager = new Pager()

  tableColumns:Array<FieldConfig> = []

  tableItems:[]

  selection:[]

  config = {fields:[],sort:""}


}