import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/shp-prod-label',
  name: 'ShpProdLabel',
  meta: {
    title: '标签管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpProdLabelList',
      meta: {
        title: '标签列表'
      },
      component: () => {
        return import('@/views/shp-prod-label/ShpProdLabelList.vue')
      }
    },
    {
      path: 'add',
      name: 'ShpProdLabelAdd',
      meta: {
        title: '标签新增'
      },
      component: () => {
        return import('@/views/shp-prod-label/ShpProdLabelAdd.vue')
      }
    },
    {
      path: 'edit',
      name: 'ShpProdLabelEdit',
      meta: {
        title: '标签编辑'
      },
      component: () => {
        return import('@/views/shp-prod-label/ShpProdLabelEdit.vue')
      }
    }
  ]
}
export default routers
