import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class DataEntityFieldApi {

    static  async  delete(dataEntityFieldParam:{
           entityFlag?:string
           name?:string
           flag?:string
           tableFieldName?:string
           sort?:number
           defaultValue?:string
           type?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           sortNum?:number
           entityId?:number
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityFieldParam
        ))


  const resp = await $http.post("/api/dataEntityField/delete",$_params,config);
  return resp;
  }
    static  async  batchDelete(ids:{
           ids?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ids
        ))


  const resp = await $http.post("/api/dataEntityField/batchDelete",$_params,config);
  return resp;
  }
    static  async  add(dataEntityFieldParam:{
           entityFlag?:string
           name?:string
           flag?:string
           tableFieldName?:string
           sort?:number
           defaultValue?:string
           type?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           sortNum?:number
           entityId?:number
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityFieldParam
        ))


  const resp = await $http.post("/api/dataEntityField/add",$_params,config);
  return resp;
  }
    static  async  update(dataEntityFieldParam:{
           entityFlag?:string
           name?:string
           flag?:string
           tableFieldName?:string
           sort?:number
           defaultValue?:string
           type?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           sortNum?:number
           entityId?:number
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityFieldParam
        ))


  const resp = await $http.post("/api/dataEntityField/update",$_params,config);
  return resp;
  }
    static  async  list(dataEntityFieldParam:{
           entityFlag?:string
           name?:string
           flag?:string
           tableFieldName?:string
           sort?:number
           defaultValue?:string
           type?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           sortNum?:number
           entityId?:number
    }={}
    ,
pagerBean:{
           pageIndex?:any
           pageSize?:any
           isFirst?:boolean
           isLast?:boolean
           itemCount?:any
           onPage?:boolean
           pageCount?:any
           orders?:any
           conds?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityFieldParam
            ,
        pagerBean
        ))


  const resp = await $http.post("/api/dataEntityField/list",$_params,config);
  return resp;
  }
    static  async  detail(dataEntityFieldParam:{
           entityFlag?:string
           name?:string
           flag?:string
           tableFieldName?:string
           sort?:number
           defaultValue?:string
           type?:string
           primaryKey?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           sortNum?:number
           entityId?:number
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataEntityFieldParam
        ))


  const resp = await $http.post("/api/dataEntityField/detail",$_params,config);
  return resp;
  }

}