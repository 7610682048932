<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue'
import DcMultiPicWallUploader from 'dc-comp-sdk/src/components/form/DcMultiPicWallUploader.vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
  skuItems: {
    type: Array,
    default() {
      return []
    }
  },
  skuInfo: {
    type: Object,
    default() {
      return {}
    }
  }
})


const emit = defineEmits(['update:skuItems'])

const addItemFormInst = ref()

const addItemValueInsts = ref([])

const addItemFormData = {
  name: '',
  values: []
}

const addItemValueFormData = {
  itemValue: ''
}

const addItemFormRules = ref({
  name: [
    { required: true, message: '规格名不能为空', trigger: 'blur' }
  ]
})

const addItemFormValueRules = ref({
  itemValue: [
    { required: true, message: '规格名不能为空', trigger: 'blur' }
  ]
})
const addItemFd = ref(JSON.parse(JSON.stringify(addItemFormData)))
const addItemValueFd = ref(JSON.parse(JSON.stringify(addItemValueFormData)))

const { skuItems, skuInfo } = toRefs(props)
const showPopover = ref(false)
const showItemValuePopover = ref([false])

async function handleResetAddItem() {
  addItemFd.value = JSON.parse(JSON.stringify(addItemFormData))
}

async function handleResetAddItemValue() {
  addItemValueFd.value = JSON.parse(JSON.stringify(addItemValueFormData))
}

async function handleAddItem() {
  let checkForm = await addItemFormInst.value.validate()
  if (!checkForm) {
    return
  }

  //判断规格项是否存在
  if (skuItems.value.filter(e => e.name == addItemFd.value.name).length) {
    ElMessage.error('规格项已存在')
    return
  }

  skuItems.value.push(JSON.parse(JSON.stringify(addItemFd.value)))
  showPopover.value = false
  handleResetAddItem()
}

async function handleAddItemValue(item, index) {
  let checkForm = await addItemValueInsts.value[index].validate()
  if (!checkForm) {
    return
  }

  //判断规格项是否存在
  if (item.values.filter(e => e.itemValue == addItemValueFd.value.itemValue).length) {
    ElMessage.error('规格值已存在')
    return
  }

  item.values.push(JSON.parse(JSON.stringify(addItemValueFd.value)))
  showItemValuePopover.value[index] = false
  handleResetAddItemValue()
}

function combineSkus(skus) {
  const result = []

  function combine(currentCombination, index) {
    if (index === skus.length) {
      const combinationWithComp = { ...currentCombination }
      combinationWithComp.skuCombo = Object.entries(currentCombination)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      result.push(combinationWithComp)
      return
    }

    const currentSku = skus[index]
    currentSku.values.forEach(value => {
      currentCombination[currentSku.name] = value.itemValue
      combine(currentCombination, index + 1)
    })
  }

  combine({}, 0)

  return result
}

function handleChangeDefChoose(row) {
  if (row.$skuInfo.defChoose == 'Y') {
    for (let key in skuInfo.value) {
      if (key != row.$skuInfo.skuCombo) {
        skuInfo.value[key].defChoose = 'N'
      }
    }
  }
}

const skuData = computed(() => {
  let i = skuItems.value.filter(e => e.values.length)
  if (!i.length) {
    return []
  }
  const d = combineSkus(i)
  const skuCombos = []
  d.forEach((item) => {
    skuCombos.push(item.skuCombo)
    if (!skuInfo.value[item.skuCombo]) {
      skuInfo.value[item.skuCombo] = {
        pics: '',
        defChoose: 'N',
        price: '',
        origPrice: '',
        stockQty: '',
        stockQtyAlert: '',
        skuCombo: item.skuCombo
      }
    }
    item.$skuInfo = skuInfo.value[item.skuCombo]
  })
  for (let key in skuInfo.value) {
    if (skuCombos.indexOf(key) == -1) {
      delete skuInfo.value[key]
    }
  }
  return d
})


handleResetAddItem()
</script>

<template>
  <div class="w-full">
    <div v-for="(item,index) in skuItems" class="mb-[12px]">
      <div class="mr-[12px]">
        <el-input class="!w-[200px]" v-model="item.name"></el-input>
        <el-link class="ml-[12px]" @click="items.splice(items.indexOf(item),1)" :underline="false" type="danger">
          删除规格
        </el-link>

      </div>
      <el-tag
        class="mr-[12px] pl-[12px] pr-[12px]"
        v-for="v in item.values"
        :key="v.itemValue"
        closable
        :disable-transitions="false"
        @close="item.values.splice(item.values.indexOf(v),1)"
      >
        {{ v.itemValue }}
      </el-tag>
      <el-popover :visible="showItemValuePopover[index]" placement="right" :width="380" trigger="click">
        <template #reference>
          <el-link type="primary" @click="showItemValuePopover[index]=true" :underline="false">添加规格值</el-link>
        </template>
        <el-form :model="addItemValueFd" :ref="el => addItemValueInsts[index] = el" :rules="addItemFormValueRules"
                 size="small">
          <el-form-item label="规格值" prop="itemValue">
            <el-input v-model="addItemValueFd.itemValue" placeholder="规格值"></el-input>
          </el-form-item>
          <el-form-item class="!mb-0">
            <el-button @click="handleAddItemValue(item,index)" type="primary">确定</el-button>
            <el-button @click="showItemValuePopover[index] = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-popover>
    </div>
    <div>
      <el-popover :visible="showPopover" placement="right" :width="380" trigger="click">
        <template #reference>
          <el-button @click="showPopover=true" size="default">添加规格项</el-button>
        </template>
        <el-form ref="addItemFormInst" :model="addItemFd" :rules="addItemFormRules" size="small">
          <el-form-item label="规格名" prop="name">
            <el-input v-model="addItemFd.name" placeholder="规格名"></el-input>
          </el-form-item>
          <el-form-item class="!mb-0">
            <el-button @click="handleAddItem" type="primary">确定</el-button>
            <el-button @click="showPopover=false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-popover>
    </div>
    <div class="mt-[12px]">
      <el-table :data="skuData" border class="w-full">
        <!--        <el-table-column label="skuCombo" prop="skuCombo"></el-table-column>-->
        <el-table-column label="商品规格">
          <template v-for="item in skuItems">
            <el-table-column :label="item.name" v-if="item.values.length" :prop="item.name"></el-table-column>
          </template>
        </el-table-column>
        <el-table-column label="SKU图片">
          <template #default="scope">
            <DcMultiPicWallUploader class="w-[128px] h-[128px]"
                                    v-model="scope.row.$skuInfo['pics']"></DcMultiPicWallUploader>
          </template>
        </el-table-column>
        <el-table-column label="售价">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['price']" placeholder="售价"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="原价">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['origPrice']" placeholder="原价"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="成本价">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['costPrice']" placeholder="成本价"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="库存">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['stockQty']" placeholder="库存"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="库存预警">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['stockQtyAlert']" placeholder="库存预警"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="是否默认选择">
          <template #default="scope">
            <el-switch @change="handleChangeDefChoose(scope.row)" active-value="Y" inactive-value="N"
                       v-model="scope.row.$skuInfo['defChoose']"></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<style scoped>

</style>