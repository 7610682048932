<script setup lang="ts">
import { watch } from 'vue'

const props = defineProps(['entity', 'action', 'show', 'title', 'width', 'params', 'initData', 'saveParams','dataFormFlag'])
const emit = defineEmits(['confirm', 'cancel'])
import CommonForm from '../dynamic-form/CommonForm.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
let modelName = route.params.model?.toString()
if (!modelName) {
  modelName = props.entity
}


function handleFinish() {
  emit('confirm')
}

watch(() => props.initData, () => {
  console.log("ddddd")

},{deep:true,immediate:true})


</script>

<template>
    <el-dialog
      @close="emit('cancel')"
      :model-value="props.show"
      :title="props.title"
      height="auto"
      :center="true"
      width="max-content"
    >
      <common-form :init-data="initData" :save-params="saveParams" :handle-finish="handleFinish" :entity="entity" :data-form-flag="dataFormFlag"></common-form>
    </el-dialog>
</template>

<style scoped>


</style>