<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'

const props = defineProps({
  showCheckBox:{
      type: Boolean,
     default:false
  },
  entity: {
    type: String,
    default: ''
  },
  queryParams:{
     type:Object,
     default:()=>{
       return  null
     }
  },
  modelValue:{
     type:Array
  },
  primaryKey: {
    default: 'id'
  },
  parentKey: {
    default: 'parentId'
  },
  labelKey: {
    default: 'name'
  }
})


const data = ref<Array<any>>([])
const treeInst =ref();
const emits = defineEmits(['update:modelValue'])

async function initData() {

  const model = await DynamicFormUtils.getListModel(props.entity)
  if(model){
    let resp = await model.dataModel.list(props.queryParams,{ onPage: false })
    data.value = DcUtils.array2Tree(resp.data.items,props.primaryKey,props.parentKey)
  }

}

watch(()=>props.queryParams,()=>{
  initData();
},{deep:true})



initData();

onMounted(()=>{
  watch(()=>props.modelValue,()=>{
   if(props.modelValue) treeInst.value.setCheckedKeys(props.modelValue)
  },{deep:true,immediate:true})
})

function handleCheck(event,data){
    emits('update:modelValue',data.checkedKeys);
}

</script>

<template>
    <el-tree
      ref="treeInst"
      @check="handleCheck"
      highlight-current
      :node-key="primaryKey"
      default-expand-all
      :show-checkbox="showCheckBox"
      :data="data"
      :props="{label:props.labelKey}"
    />
</template>

<style scoped>

</style>