<script setup lang="ts">
import DcDictSelect from 'dc-comp-sdk/src/components/form/DcDictSelect.vue'
import { watch } from 'vue'

const modelValue = defineModel()

function handleDelete(row) {
  modelValue.value.splice(modelValue.value.indexOf(row), 1)
}

function handleAdd() {
  modelValue.value.push({
    type: 'DATE', ruleDataObj: { weekdays: [], dateItems: [] }
  })
}

function handleAddDate(items) {
  items.push({ type: 'date', date: '', dateObj: '' })
}

function handleDeleteDate(items, item) {
  items.splice(items.indexOf(item), 1)
}

watch(modelValue, () => {
  modelValue.value.forEach((e) => {
    e.ruleDataObj.dateItems.forEach((item) => {

      if(item.dateObj){
        if (Array.isArray(item.dateObj)) {
          item.date = item.dateObj.join('@')
        } else {
          item.date = item.dateObj
        }
      }else if(item.date){
        if(!item.init){
          if(item.date.indexOf('@') != -1){
            item.dateObj = item.date.split('@')
          }else{
            item.dateObj = item.date;
          }
          item.init = true;
        }


      }




    })
  })
}, { immediate: true, deep: true })

</script>

<template>
  <div class="w-full">
    <el-table :data="modelValue" border>
      <el-table-column label="条件类型" width="220px" prop="type">
        <template #default="scope">
          <DcDictSelect v-model="scope.row.type" dict-type="DCSHP_SHP_VIP_DISCOUNT_RULE_TYPE"></DcDictSelect>
        </template>
      </el-table-column>
      <el-table-column label="条件规则" prop="ruleData">
        <template #default="scope">
          <el-checkbox-group v-if="scope.row.type === 'WEEKDAY'" v-model="scope.row.ruleDataObj.weekdays">
            <el-checkbox label="星期一" value="1" />
            <el-checkbox label="星期二" value="2" />
            <el-checkbox label="星期三" value="3" />
            <el-checkbox label="星期四" value="4" />
            <el-checkbox label="星期五" value="5" />
            <el-checkbox label="星期六" value="6" />
            <el-checkbox label="星期日" value="7" />
          </el-checkbox-group>

          <template v-if="scope.row.type === 'DATE'">
            <div class="mb-[16px]" v-for="item in scope.row.ruleDataObj.dateItems">
              <div class="flex items-center">
                <el-radio-group v-model="item.type">
                  <el-radio value="date" size="large" @change="item.dateObj=''">单日</el-radio>
                  <el-radio value="daterange" size="large" @change="item.dateObj=[]">日期段</el-radio>
                </el-radio-group>
              </div>
              <div class="flex items-center">
                <el-date-picker value-format="YYYY-MM-DD"  placeholder="请选择日期" start-placeholder="请选择开始日期"
                                v-model="item.dateObj" end-placeholder="请选择结束日期"
                                :type="item.type"></el-date-picker>
                <el-icon size="16px" @click="handleDeleteDate(scope.row.ruleDataObj.dateItems,item)" class="ml-[24px]"
                         color="#F56C6C">
                  <DeleteFilled />
                </el-icon>
              </div>
            </div>
            <div>
              <el-link type="primary" @click="handleAddDate(scope.row.ruleDataObj.dateItems)" :underline="false">
                新增日期
              </el-link>
            </div>

          </template>

        </template>
      </el-table-column>
      <el-table-column width="80px" label="操作" align="right">
        <template #default="scope">
          <el-link :underline="false" @click="handleDelete(scope.row)" type="danger">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-[16px]">
      <el-button size="default" @click="handleAdd">新增条件</el-button>
    </div>
  </div>
</template>

<style scoped>

</style>