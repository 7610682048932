import FieldConfig from '../../models/dynamic-form/FieldConfig'
import type DcResponse from '@/models/DcResponse'

export default abstract class DataModel {

  $name: string = ''


  detail(...args: any[]): Promise<DcResponse> {
  };

  update(...args: any[]): Promise<DcResponse> {
  }

  add(...args: any[]): Promise<DcResponse> {
  }

  delete(...args: any[]): Promise<DcResponse> {
  }

  list(...args: any[]): Promise<DcResponse> {
  }

  batchDelete(...args: any[]): Promise<DcResponse> {
  }

  async $configFieldShow(configs: string[], show = true) {
    configs.forEach((c) => {
      this[c].show = show
    })
  }

  async $configField(configs: FieldConfig[]) {
    configs.forEach((c) => {
      this[c.name].label = c.label
    })
  }

}