export default class {

  static $name:string="@DC_DATA_LIST_SEARCH_FIELD"
    //ID
    id?:number;
    //版本号
    version?:number;
    //逻辑删除标识
    valid?:number;
    //创建时间
    createTime?:date;
    //最后修改时间
    updateTime?:date;
    //数据实体标识
    entityFlag?:string;
    //数据列表ID
    dataListId?:number;
    //数据列表标识
    dataListFlag?:string;
    //类型
    type?:string;
    //表单字段标识
    dataEntityFieldFlag?:string;
    //条件名称
    name?:string;
    //查询条件
    cond?:string;
    //默认值
    defaultValue?:string;
    //是否显示
    showStatus?:string;
    //排序
    sortNum?:number;
    //自定义组件
    diyComp?:string;
    //自定义组件参数
    diyCompProps?:string;

}