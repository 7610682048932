<script setup lang="ts">
import DcEditor from 'dc-comp-sdk/src/components/form/DcEditor.vue'
import { ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import qs from 'qs'
import $api from '../config/api'

let props = defineProps({
  name: {
    type: String,
    default: ''
  }
})

const r = ref('')
const v = ref('')

async function handleLoadData() {
  let resp = await $http.post($api.shpSetting.info, qs.stringify({ name: props.name }))
  if (resp.data.item.value) {
    v.value = resp.data.item.value
    r.value = resp.data.item.value
  }

}

async function handleSave() {
  if (r.value == v.value) {
    return
  }
  try {
    let resp = await $http.post($api.shpSetting.save, qs.stringify({ name: props.name, value: v.value }))
    r.value = v.value
  } catch (e) {

  }
}


handleLoadData()


</script>

<template>
  <div>
    <DcEditor v-if="name" v-model="v"></DcEditor>
    <div class="mt-[12px]">
      <el-button @click="handleSave" type="primary" :disabled="r == v">保存</el-button>
    </div>
  </div>
</template>

<style scoped>

</style>