<script setup lang="ts">
import { ref } from 'vue'
import qs from 'qs'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import DcDictSelect from 'dc-comp-sdk/src/components/form/DcDictSelect.vue'
import DcMultiPicWallUploader from 'dc-comp-sdk/src/components/form/DcMultiPicWallUploader.vue'

const v = ref(false)
const emit = defineEmits(['finish'])
const formData = {
  id: '',
  orderId: '',
  amount: '',
  reason: '',
  refundType: '',
  refundVoucher: '',
  status: ''
}

let inputData = {}
const formInst = ref()
const fd = ref(JSON.parse(JSON.stringify(formData)))

const rules = ref({
  amount: [
    { required: true, message: '退款金额不能为空', trigger: 'blur' }
  ],
  refundType: [
    { required: true, message: '退款方式不能为空', trigger: 'blur' }
  ],
  status: [
    { required: true, message: '退款状态不能为空', trigger: 'blur' }
  ],
  refundVoucher: [
    { required: true, message: '退款凭证不能为空', trigger: 'blur' }
  ]

})


async function show(data: { id, orderId, paySum }) {
  v.value = true
  inputData = data
  handleReset()
}

function saveUrl() {
  return fd.value.id ? $api.shpOrderRefund.update : $api.shpOrderRefund.add
}

async function handleSave() {
  let checkForm = await formInst.value.validate()
  if (!checkForm) {
    return
  }

  try {
    await $http.post(saveUrl(), qs.stringify(fd.value), { showTips: true })
    v.value = false
    emit('finish')
  } catch (e: any) {
    if (e.status === 400) {
      console.log(e)
      let errorTips = new Map()
      e.data.errorFields.forEach((e: any) => {
        errorTips.set(e.name, e.message)
      })
      formInst.value.fields.forEach((e: any) => {
        if (errorTips.get(e.prop)) {
          e.validateState = 'error'
          e.validateMessage = errorTips.get(e.prop)
          //手动校验  显示错误文本
          formInst.value.validateField(e.prop)
        }
      })
    }
  }
}

function handleReset() {
  fd.value = JSON.parse(JSON.stringify(Object.assign({}, Object.assign({}, formData), inputData)))
}

function handleClose() {
  fd.value = JSON.parse(JSON.stringify(Object.assign({}, Object.assign({}, formData))))

}

defineExpose({ show })

</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="500px">
    <el-form ref="formInst" :model="fd" :rules="rules" size="large" label-width="auto" class="bg-[#fff] pt-[12px]">

      <el-form-item label="退款金额" prop="amount">
        <el-input v-model="fd.amount"></el-input>
      </el-form-item>

      <el-form-item label="退款原因" prop="reason">
        <el-input type="textarea" v-model="fd.reason"></el-input>
      </el-form-item>

      <el-form-item label="退款方式" prop="refundType">
        <DcDictSelect v-model="fd.refundType" dict-type="PAY_TYPE"></DcDictSelect>
      </el-form-item>

      <el-form-item label="退款状态" prop="status">
        <DcDictSelect v-model="fd.status" dict-type="DCSHP_ORDER_PAY_STATUS"></DcDictSelect>
      </el-form-item>

      <el-form-item label="退款凭证" prop="paymentVoucher">
        <DcMultiPicWallUploader v-model="fd.refundVoucher"></DcMultiPicWallUploader>
      </el-form-item>


      <div class="flex justify-center">
        <el-button @click="handleSave" type="primary" size="large">保存</el-button>
        <el-button @click="handleReset" size="large">重置</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<style scoped>

</style>