<script setup lang="ts">

import type DataList from '../../../../data-entity/DataList'

const props = defineProps(['entityObj'])
import { computed, ref } from 'vue'
import CommonList from '../../../../src/components/dynamic-form/CommonList.vue'
import DataListSearchEditor from './DataListSearchEditor.vue'
import DataListFieldEditor from './DataListFieldEditor.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DataListOpBtnEditor from './DataListOpBtnEditor.vue'
const currentRow = ref<DataList>()

function handleCurrentRowChange(row:DataList){
  currentRow.value = row;
}
const savePrams = computed(()=>{return {entityFlag:props.entityObj.flag,entityId:props.entityObj.id}})
const queryParams = computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({entityFlag:props.entityObj.flag,entityId:props.entityObj.id})}})
const fieldQueryParams = computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({entityFlag:props.entityObj.flag,dataListId:currentRow.value?.id,dataListFlag:currentRow.value?.flag})}})
const fieldSaveParams = computed(()=>{return {entityFlag:props.entityObj.flag,dataListId:currentRow.value?.id,dataListFlag:currentRow.value?.flag}})
const activeTab = ref("field")
</script>

<template>
  <el-row  :gutter="20">
    <el-col :span="10">
      <common-list :query-params="queryParams" :save-params="savePrams"  @current-row-change="handleCurrentRowChange"  entity="DC_DATA_LIST"></common-list>
    </el-col>
    <el-col v-if="currentRow" :span="14">
      <el-descriptions class="mb-[12px]" border>
        <el-descriptions-item label="列表名称">{{currentRow.name}}</el-descriptions-item>
        <el-descriptions-item label="列表标识">{{currentRow.flag}}</el-descriptions-item>
      </el-descriptions>
      <el-tabs v-model="activeTab"  type="border-card">
        <el-tab-pane name="field"  label="列表字段设计">
          <data-list-field-editor v-if="activeTab === 'field'" :query-params="fieldQueryParams" :entity-obj="entityObj" :save-params="fieldSaveParams"  ></data-list-field-editor>
        </el-tab-pane>
        <el-tab-pane name="search" label="查询条件设计">
          <data-list-search-editor  v-if="activeTab === 'search'" :query-params="fieldQueryParams" :entity-obj="entityObj" :save-params="fieldSaveParams" ></data-list-search-editor>
        </el-tab-pane>
        <el-tab-pane name="opBtn" label="操作按钮设计">
          <data-list-op-btn-editor :query-params="fieldQueryParams" :entity-obj="entityObj" :save-params="fieldSaveParams" v-if="activeTab === 'opBtn'"></data-list-op-btn-editor>
        </el-tab-pane>
      </el-tabs>

    </el-col>
  </el-row>
</template>

<style scoped>

</style>