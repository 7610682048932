<template>
  <span>{{ formattedDate }}</span>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  format: {
    type: String,
    default: 'YYYY-MM-DD HH:mm:ss',
  },
});


const formattedDate = computed(() => dayjs(props.modelValue).format(props.format));
</script>

<style scoped>
/* 样式可以根据需要进行调整 */
</style>
