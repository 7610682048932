import DataModel from '../../models/dynamic-form/DataModel'

export default class BaseModel {

  dataModel: DataModel

  reqConfig: {}


  constructor(dataModel: DataModel) {
    this.dataModel = dataModel
  }


  async list(...args) {

    return this.dataModel.list(this.mergeParams(args), this.reqConfig)
  }


  async detail(...args) {

    return this.dataModel.detail(this.mergeParams(args), this.reqConfig)

  }

  async delete(...args) {

    return this.dataModel.delete(this.mergeParams(args), this.reqConfig)

  }

  async add(...args) {
    console.log("xxxaaaaas",this.reqConfig)

    return this.dataModel.add(this.mergeParams(args), this.reqConfig)
  }

  cfg(config) {
    const m = new BaseModel(this.dataModel)
    m.reqConfig = config
    console.log("xxxas",config)
    return m
  }

  async update(...args) {

    return this.dataModel.update(this.mergeParams(args), this.config)
  }

  async batchDelete(...args) {

    return this.dataModel.batchDelete(this.mergeParams(args), this.config)
  }

  mergeParams(args: any[]) {
    let p = {}
    args.forEach((e) => {
      p = Object.assign(p, e)
    })
    return p
  }

}