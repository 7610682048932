import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/shp-setting',
  name: 'ShpSetting',
  meta: {
    title: '商城设置'
  },
  children: [
    {
      path: 'info',
      name: 'ShpSettingInfo',
      meta: {
        title: '商城设置'
      },
      component: () => {
        return import('@/views/shp-setting/ShpSettingInfo.vue')
      }
    }
  ]
}
export default routers
