import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-feedback',
  name:'ShpFeedback',
  meta: {
    title: '用户反馈管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpFeedbackList',
      meta: {
        title: '用户反馈列表'
      },
      component: ()=>{return import("@/views/shp-feedback/ShpFeedbackList.vue")}
    },
    {
      path: 'add',
      name: 'ShpFeedbackAdd',
      meta: {
        title: '用户反馈新增'
      },
      component: ()=>{return import("@/views/shp-feedback/ShpFeedbackAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpFeedbackEdit',
      meta: {
        title: '用户反馈编辑'
      },
      component: ()=>{return import("@/views/shp-feedback/ShpFeedbackEdit.vue")}
    }
  ]
}
export default routers
