//域名
const domain = window.location.host + '/api'

//端口
const port = null

//路径
const root = window.location.protocol + '//' + domain + (port ? (':' + port) : '')

//接口
const api = {
  shpProdCat: {
    add: `${root}/shpProdCat/add`,
    update: `${root}/shpProdCat/update`,
    detail: `${root}/shpProdCat/detail`,
    list: `${root}/shpProdCat/list`,
    delete: `${root}/shpProdCat/delete`,
    batchDelete: `${root}/shpProdCat/batchDelete`
  },
  shpUnit: {
    add: `${root}/shpUnit/add`,
    update: `${root}/shpUnit/update`,
    detail: `${root}/shpUnit/detail`,
    list: `${root}/shpUnit/list`,
    delete: `${root}/shpUnit/delete`,
    batchDelete: `${root}/shpUnit/batchDelete`
  },
  shpProdLabel: {
    add: `${root}/shpProdLabel/add`,
    update: `${root}/shpProdLabel/update`,
    detail: `${root}/shpProdLabel/detail`,
    list: `${root}/shpProdLabel/list`,
    delete: `${root}/shpProdLabel/delete`,
    batchDelete: `${root}/shpProdLabel/batchDelete`
  },
  shpVipLevel: {
    add: `${root}/shpVipLevel/add`,
    update: `${root}/shpVipLevel/update`,
    detail: `${root}/shpVipLevel/detail`,
    list: `${root}/shpVipLevel/list`,
    delete: `${root}/shpVipLevel/delete`,
    batchDelete: `${root}/shpVipLevel/batchDelete`
  },
  shpVipDiscount: {
    add: `${root}/shpVipDiscount/add`,
    update: `${root}/shpVipDiscount/update`,
    detail: `${root}/shpVipDiscount/detail`,
    list: `${root}/shpVipDiscount/list`,
    delete: `${root}/shpVipDiscount/delete`,
    batchDelete: `${root}/shpVipDiscount/batchDelete`
  },
  shpSupplier: {
    add: `${root}/shpSupplier/add`,
    update: `${root}/shpSupplier/update`,
    detail: `${root}/shpSupplier/detail`,
    list: `${root}/shpSupplier/list`,
    delete: `${root}/shpSupplier/delete`,
    batchDelete: `${root}/shpSupplier/batchDelete`
  },
  shpAgent: {
    add: `${root}/shpAgent/add`,
    update: `${root}/shpAgent/update`,
    detail: `${root}/shpAgent/detail`,
    list: `${root}/shpAgent/list`,
    delete: `${root}/shpAgent/delete`,
    batchDelete: `${root}/shpAgent/batchDelete`
  },
  shpBanner: {
    add: `${root}/shpBanner/add`,
    update: `${root}/shpBanner/update`,
    detail: `${root}/shpBanner/detail`,
    list: `${root}/shpBanner/list`,
    delete: `${root}/shpBanner/delete`,
    batchDelete: `${root}/shpBanner/batchDelete`
  },
  shpArticle: {
    add: `${root}/shpArticle/add`,
    update: `${root}/shpArticle/update`,
    detail: `${root}/shpArticle/detail`,
    list: `${root}/shpArticle/list`,
    delete: `${root}/shpArticle/delete`,
    batchDelete: `${root}/shpArticle/batchDelete`
  },
  shpSetting: {
    save: `${root}/shpSetting/save`,
    info: `${root}/shpSetting/info`
  },
  shpProduct: {
    add: `${root}/shpProduct/add`,
    update: `${root}/shpProduct/update`,
    detail: `${root}/shpProduct/detail`,
    list: `${root}/shpProduct/list`,
    delete: `${root}/shpProduct/delete`,
    batchDelete: `${root}/shpProduct/batchDelete`
  },
  shpOrder: {
    add: `${root}/shpOrder/add`,
    update: `${root}/shpOrder/update`,
    detail: `${root}/shpOrder/detail`,
    list: `${root}/shpOrder/list`,
    delete: `${root}/shpOrder/delete`,
    cancel: `${root}/shpOrder/cancel`,
    batchDelete: `${root}/shpOrder/batchDelete`
  },
  bogoProduct: {
    add: `${root}/bogoProduct/add`,
    update: `${root}/bogoProduct/update`,
    detail: `${root}/bogoProduct/detail`,
    list: `${root}/bogoProduct/list`,
    delete: `${root}/bogoProduct/delete`,
    batchDelete: `${root}/bogoProduct/batchDelete`
  },
  bundleProduct: {
    add: `${root}/bundleProduct/add`,
    update: `${root}/bundleProduct/update`,
    detail: `${root}/bundleProduct/detail`,
    list: `${root}/bundleProduct/list`,
    delete: `${root}/bundleProduct/delete`,
    batchDelete: `${root}/bundleProduct/batchDelete`
  },
  shpUser: {
    add: `${root}/shpUser/add`,
    update: `${root}/shpUser/update`,
    detail: `${root}/shpUser/detail`,
    list: `${root}/shpUser/list`,
    delete: `${root}/shpUser/delete`,
    batchDelete: `${root}/shpUser/batchDelete`
  },
  shpFeedback: {
    add: `${root}/shpFeedback/add`,
    update: `${root}/shpFeedback/update`,
    detail: `${root}/shpFeedback/detail`,
    list: `${root}/shpFeedback/list`,
    delete: `${root}/shpFeedback/delete`,
    batchDelete: `${root}/shpFeedback/batchDelete`
  },
  shpOrderPayRecord: {
    add: `${root}/shpOrderPayRecord/add`,
    update: `${root}/shpOrderPayRecord/update`,
    detail: `${root}/shpOrderPayRecord/detail`,
    list: `${root}/shpOrderPayRecord/list`,
    delete: `${root}/shpOrderPayRecord/delete`,
    batchDelete: `${root}/shpOrderPayRecord/batchDelete`
  },
  shpOrderRefund: {
    add: `${root}/shpOrderRefund/add`,
    update: `${root}/shpOrderRefund/update`,
    detail: `${root}/shpOrderRefund/detail`,
    list: `${root}/shpOrderRefund/list`,
    delete: `${root}/shpOrderRefund/delete`,
    batchDelete: `${root}/shpOrderRefund/batchDelete`
  }
}

let $api = Object.assign({
  $base: {
    domain: domain, port: port, root: root
  }
}, api)
export default $api
