<script setup lang="ts">
import IconI18n from '../icons/IconI18n.vue'
import DropdownConfig from '../../types/models/layout/DropdownConfig'
import screenfull from 'screenfull';
import { computed, ref } from 'vue'

defineProps({
  isCollapse: {
    default: false
  },
  userDropdownConfig:DropdownConfig,
  langDropdownConfig:DropdownConfig,
})
const emit = defineEmits(['onHeaderTriggerClick','onMoreClick'])

function handleFullscreen(){
  if (screenfull.isEnabled) {
    screenfull.toggle();
  }
}

const isFullScreen = ref(screenfull.isFullscreen);
screenfull.on('change', () => {
  isFullScreen.value = screenfull.isFullscreen;
});
</script>

<template>
  <div class="flex items-center flex-1 h-full">
    <span
      @click="emit('onHeaderTriggerClick')"
      class="dc-layout-header-trigger flex p-[12px] h-full items-center"
    >
      <el-icon size="18" color="#515a6e">
        <Fold v-if="!isCollapse" />
        <Expand v-else />
      </el-icon>
    </span>

    <span class="dc-layout-header-trigger flex p-[12px] h-full items-center">
      <el-icon size="18" color="#515a6e">
        <RefreshRight />
      </el-icon>
    </span>
  </div>

  <div class="flex items-center h-full">
    <span class="dc-layout-header-trigger flex p-[12px] h-full items-center">
      <el-icon size="18" color="#515a6e">
        <Files />
      </el-icon>
    </span>

    <span  @click="handleFullscreen" class="dc-layout-header-trigger flex p-[12px] h-full items-center">
      <el-icon v-if="!isFullScreen" size="18" color="#515a6e">
        <span class="iconfont dc-icon-full-screen"></span>
      </el-icon>
      <el-icon v-else size="18" color="#515a6e">
        <span class="iconfont dc-icon-exit-full-screen"></span>
      </el-icon>
    </span>

    <span class="dc-layout-header-trigger flex p-[12px] h-full items-center">
      <el-icon size="18" color="#515a6e">
        <Notification />
      </el-icon>
    </span>

    <el-dropdown class="dc-layout-header-trigger flex p-[12px] h-full items-center" size="large">
      <span class="flex p-[12px] h-full items-center !border-none">
        <el-avatar
          :size="24"
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        />
        <span class="pl-[12px] text-[14px] text-[#515a6e]">DevCude</span>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(item,index) in userDropdownConfig.items" :key="index" :icon="item.icon" :divided="item.divided" @click="userDropdownConfig.handleClick()">{{item.title}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>


    <el-dropdown class="dc-layout-header-trigger flex p-[12px] h-full items-center" size="large">
      <span class="flex p-[12px] h-full items-center !border-none">
        <el-icon size="18" color="#515a6e">
        <IconI18n></IconI18n>
      </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(item,index) in langDropdownConfig.items" :key="index" :icon="item.icon" :divided="item.divided" @click="userDropdownConfig.handleClick()">{{item.title}}</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <span @click="emit('onMoreClick')" class="dc-layout-header-trigger flex p-[12px] h-full items-center">
      <el-icon class="rotate-90" size="18" color="#515a6e">
        <MoreFilled />
      </el-icon>
    </span>
  </div>
</template>

<style scoped></style>
