import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-product',
  name:'ShpProduct',
  meta: {
    title: '商品管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpProductList',
      meta: {
        title: '商品列表'
      },
      component: ()=>{return import("@/views/shp-product/ShpProductList.vue")}
    },
    {
      path: 'add',
      name: 'ShpProductAdd',
      meta: {
        title: '商品新增'
      },
      component: ()=>{return import("@/views/shp-product/ShpProductAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpProductEdit',
      meta: {
        title: '商品编辑'
      },
      component: ()=>{return import("@/views/shp-product/ShpProductEdit.vue")}
    }
  ]
}
export default routers
