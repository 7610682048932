import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './assets/main.scss'
import DcCompSdk from 'dc-comp-sdk'
import Components from './components'
import router from './router'
// 全局注册组件
const app = createApp(App)
app.use(createPinia())
Components.register(app)
async function init(){
  await DcCompSdk.init(app, router)
//初始化SDK
  app.mount('#app')
}
init()




