<script setup lang="ts">
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { ref } from 'vue'
import PkgMustItemEditor from '@/components/PkgMustItemEditor.vue'

const v = defineModel([])
const formInst = ref()
const showPopover = ref(false)
const groupInst = ref([])
const showGroupPopover = ref([])
const formData = {
  name: '',
  requiredQty: '',
  items: []
}

const rules = ref({
  name: [
    { required: true, message: '分组名称不能为空', trigger: 'blur' }
  ],
  requiredQty: [
    { required: true, message: '自选数量不能为空', trigger: 'blur' }
  ]
})
const fd = ref(JSON.parse(JSON.stringify(formData)))

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

async function handleAdd() {

  let checkForm = await formInst.value.validate()
  if (!checkForm) {
    return
  }

  v.value.push(JSON.parse(JSON.stringify(fd.value)))
  fd.value = JSON.parse(JSON.stringify(formData))
  showPopover.value = false
}

async function handleUpdateGroup(j) {

  let checkForm = await groupInst.value[j].validate()
  if (!checkForm) {
    return
  }
  v.value[j] = fd.value
  fd.value = JSON.parse(JSON.stringify(formData))
  showGroupPopover.value[j] = false
}


</script>

<template>
  <div>
    <div>
      <el-popover :visible="showPopover" placement="right" :width="380" trigger="click">
        <template #reference>
          <el-button @click="()=>{fd=Object.assign({},formData);showPopover=true}" size="default">添加自选分组
          </el-button>
        </template>
        <el-form :model="fd" ref="formInst" :rules="rules"
                 label-width="80px"
                 size="small">
          <el-form-item label="分组名称" prop="name">
            <el-input v-model="fd.name" placeholder="分组名称"></el-input>
          </el-form-item>
          <el-form-item label="自选数" prop="requiredQty">
            <el-input v-model="fd.requiredQty" placeholder="自选数"></el-input>
          </el-form-item>
          <el-form-item class="!mb-0">
            <el-button @click="handleAdd(item)" type="primary">确定</el-button>
            <el-button @click="showPopover = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-popover>

      <div v-for="(group,j) in v" class="mt-[12px]">
        <div class="flex">
          <el-text size="default" class="!mr-[24px]">分组名称: {{ group.name }}</el-text>
          <el-text size="default" class="!mr-[24px]">自选数: {{ group.requiredQty }}</el-text>
          <el-popover :visible="showGroupPopover[j]" placement="right" :width="380" trigger="click">
            <template #reference>
              <el-link type="primary" class="!mr-[12px]" @click="()=>{fd=Object.assign({},group);showGroupPopover[j]=true;}"
                       :underline="false">修改
              </el-link>
            </template>
            <el-form :model="fd" :ref="el => groupInst[j] = el" :rules="rules" label-width="80px"
                     size="small">
              <el-form-item label="分组名称" prop="name">
                <el-input v-model="fd.name" placeholder="分组名称"></el-input>
              </el-form-item>
              <el-form-item label="自选数" prop="requiredQty">
                <el-input v-model="fd.requiredQty" placeholder="自选数"></el-input>
              </el-form-item>
              <el-form-item class="!mb-0">
                <el-button @click="handleUpdateGroup(j)" type="primary">确定</el-button>
                <el-button @click="showGroupPopover[j] = false">取消</el-button>
              </el-form-item>
            </el-form>
          </el-popover>
          <el-link type="danger" @click="v.splice(j,1)" :underline="false">删除</el-link>

        </div>
        <pkg-must-item-editor v-model="group.items" :key="`group_${j}`"></pkg-must-item-editor>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>