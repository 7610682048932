import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-prod-cat',
  name:'ShpProCat',
  meta: {
    title: '商品分类'
  },
  children: [
    {
      path: 'list',
      name: 'ShpProdCatList',
      meta: {
        title: '商品分类列表'
      },
      component: ()=>{return import("@/views/shp-prod-cat/ShpProdCatList.vue")}
    },
    {
      path: 'add',
      name: 'ShpProdCatAdd',
      meta: {
        title: '商品分类新增'
      },
      component: ()=>{return import("@/views/shp-prod-cat/ShpProdCatAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpProdCatEdit',
      meta: {
        title: '商品分类编辑'
      },
      component: ()=>{return import("@/views/shp-prod-cat/ShpProdCatEdit.vue")}
    }
  ]
}
export default routers
