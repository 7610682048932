import type FieldConfig from '@/models/dynamic-form/FieldConfig'
import type BtnConfig from '@/models/dynamic-form/BtnConfig'

export default class DataListConfig{
  flag?:string
  isPaginated?:string
  showLeftBtnGroup?:string
  showRightBtnGroup?:string
  showTableOp?:string
  searchFields:Array<FieldConfig> = []
  fields:Array<FieldConfig> = []
  opBtns:Array<BtnConfig> = []
}