<script setup lang="ts">
import { ref } from 'vue'
import ProdItemChooseList from '@/components/ProdItemChooseList.vue'

const v = defineModel([])
const formInst = ref()
const formData = {
  name: '',
  requiredQty: '',
  items: []
}

const fd = ref(JSON.parse(JSON.stringify(formData)))


</script>

<template>
  <div>
    <div v-for="(group) in v">
      <div class="flex flex-col">
        <div class="flex justify-between">
          <el-text class="!mr-[24px]">{{ group.name }}</el-text>
          <el-text size="small" type="primary">选择: {{group.items.filter(e=>e.choosed).length }} / {{ group.requiredQty }}</el-text>
        </div>
        <ProdItemChooseList v-model="group.items" :limit="group.requiredQty"></ProdItemChooseList>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>