import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-article',
  name:'ShpArticle',
  meta: {
    title: '文章管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpArticleList',
      meta: {
        title: '文章列表'
      },
      component: ()=>{return import("@/views/shp-article/ShpArticleList.vue")}
    },
    {
      path: 'add',
      name: 'ShpArticleAdd',
      meta: {
        title: '文章新增'
      },
      component: ()=>{return import("@/views/shp-article/ShpArticleAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpArticleEdit',
      meta: {
        title: '文章编辑'
      },
      component: ()=>{return import("@/views/shp-article/ShpArticleEdit.vue")}
    }
  ]
}
export default routers
