import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class AuthCompCommonApi {

    static  async  userMenus(flag:{
           flag?:string
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        flag
        ))


  const resp = await $http.post("/api/authCompCommon/userMenus",$_params,config);
  return resp;
  }
    static  async  rolePermsInfo(roleParam:{
           name?:string
           flag?:string
           description?:string
           status?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        roleParam
        ))


  const resp = await $http.post("/api/authCompCommon/rolePermsInfo",$_params,config);
  return resp;
  }
    static  async  saveRolePermsInfo(saveRolePermsInfoParam:{
           id?:number
           menuItemIdMap?:any
           actionIds?:any
           ruleIds?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {

        const $_params = Object.assign({},
        saveRolePermsInfoParam
        )

  const resp = await $http.post("/api/authCompCommon/saveRolePermsInfo",$_params,config);
  return resp;
  }

}