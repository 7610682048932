import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_TABLE"
const routers: RouteRecordRaw = {
  path: '/table',
  name:'table',
  meta: {
    title: '数据表管理'
  },
  children: [
    {
      path: 'list',
      name: 'table-list',
      meta: {
        title: '数据表列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }
  ]
}
export default routers
