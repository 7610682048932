<template>
  <div ref="editor" :style="{ width: '100%' }"></div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import jsonlint from 'jsonlint-mod';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  theme: {
    type: String,
    default: 'ace/theme/monokai'
  },
  mode: {
    type: String,
    default: 'ace/mode/json'
  },
  fontSize: {
    type: Number,
    default: 14
  },
  showGutter: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(['update:modelValue', 'change']);
const editor = ref(null);
let aceEditor;

const updateEditorHeight = () => {
  if (aceEditor) {
    const session = aceEditor.getSession();
    const newHeight = (session.getScreenLength() * aceEditor.renderer.lineHeight) + aceEditor.renderer.scrollBar.getWidth();
    editor.value.style.height = `${newHeight}px`;
    aceEditor.resize();
  }
};

const validateJSON = (value) => {
  try {
    jsonlint.parse(value);
    aceEditor.session.clearAnnotations();
  } catch (e) {
    aceEditor.session.setAnnotations([{
      row: e.line - 1,
      column: e.character,
      text: e.message,
      type: "error"
    }]);
  }
};

onMounted(() => {
  aceEditor = ace.edit(editor.value, {
    value: props.modelValue,
    mode: props.mode,
    theme: props.theme,
    fontSize: props.fontSize,
    autoScrollEditorIntoView: true,
    maxLines: Infinity,
    showGutter: props.showGutter
  });

  aceEditor.on('change', () => {
    const value = aceEditor.getValue();
    emit('update:modelValue', value);
    emit('change', value);
    updateEditorHeight();
    validateJSON(value);
  });

  nextTick(() => {
    updateEditorHeight();
  });

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== aceEditor.getValue()) {
        aceEditor.setValue(newValue, -1);
      }
    }
  );

  watch(
    () => props.theme,
    (newTheme) => {
      aceEditor.setTheme(newTheme);
    }
  );

  watch(
    () => props.mode,
    (newMode) => {
      aceEditor.session.setMode(newMode);
    }
  );

  watch(
    () => props.fontSize,
    (newFontSize) => {
      aceEditor.setFontSize(newFontSize);
    }
  );

  watch(
    () => props.showGutter,
    (newShowGutter) => {
      aceEditor.renderer.setShowGutter(newShowGutter);
    }
  );
});
</script>

<style>
/* 可以根据需要添加自定义样式 */
</style>
