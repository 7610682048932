import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-order',
  name:'ShpOrder',
  meta: {
    title: '订单管理管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpOrderList',
      meta: {
        title: '订单管理列表'
      },
      component: ()=>{return import("@/views/shp-order/ShpOrderList.vue")}
    },
    {
      path: 'add',
      name: 'ShpOrderAdd',
      meta: {
        title: '订单管理新增'
      },
      component: ()=>{return import("@/views/shp-order/ShpOrderAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpOrderEdit',
      meta: {
        title: '订单管理编辑'
      },
      component: ()=>{return import("@/views/shp-order/ShpOrderEdit.vue")}
    },
    {
      path: 'detail',
      name: 'ShpOrderDetail',
      meta: {
        title: '订单管理详情'
      },
      component: ()=>{return import("@/views/shp-order/ShpOrderDetail.vue")}
    }
  ]
}
export default routers
