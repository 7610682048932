<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  amount: {
    type: Number || String, default() {
      return 0
    }
  }
})
const showText = computed(() => {
  return parseFloat(props.amount).toFixed(2)
})
</script>

<template>
<el-text>
  {{ showText }}
</el-text>
</template>

<style scoped>

</style>