<script setup lang="ts">
import AmountText from '@/components/AmountText.vue'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import ShpProdChooseBox from '@/components/shp-prod-choose-box/ShpProdChooseBox.vue'
import { ref } from 'vue'

const v = defineModel([])
const shpProdChooseBox = ref()

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}


async function handleChoose() {
  shpProdChooseBox.value.show()
}

async function handleDelete(orderItem) {
  v.value.splice(v.value.indexOf(orderItem), 1)
}

</script>

<template>
  <div>
    <ShpProdChooseBox ref="shpProdChooseBox" v-model="v"></ShpProdChooseBox>


    <el-table :data="v" :row-key="(row:any)=>{return row.id}" border>
      <el-table-column type="selection" :reserve-selection="true"></el-table-column>
      <el-table-column width="120px" label="图片" prop="pics">
        <template #default="scope">
          <DcSinglePicShow width="48px" height="48px" :model-value="scope.row.pic"></DcSinglePicShow>
        </template>
      </el-table-column>
      <el-table-column label="商品编号" prop="productNum"></el-table-column>
      <el-table-column label="商品名称" prop="name"></el-table-column>
      <el-table-column label="商品分类" prop="categoryName"></el-table-column>
      <el-table-column label="规格">
        <template #default="scope">
          <template v-for="item in qsParse(scope.row.skuCombo)">
                      <span class="mr-[12px]">
                          <el-text size="small">
                        {{ item.name }} : {{ item.value }}
                      </el-text>
                      </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="售价" prop="price"></el-table-column>
      <el-table-column label="数量" prop="itemQty"></el-table-column>
      <el-table-column label="小计金额">
        <template #default="scope">
          <amount-text :amount="scope.row.price * scope.row.itemQty"></amount-text>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right">
        <template #default="scope">
          <el-link :underline="false" @click="handleDelete(scope.row)" type="danger">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-[12px]">
      <el-button size="default" @click="handleChoose" >添加</el-button>
    </div>
  </div>
</template>

<style scoped>

</style>