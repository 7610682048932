<script setup lang="ts">
import {
  ElUpload, genFileId,
  type UploadFile,
  type UploadFiles,
  type UploadInstance,
  type UploadProps,
  type UploadRawFile
} from 'element-plus'
import { computed, ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import qs from 'qs'

const props = defineProps({
  btnType: {
    type: String,
    default: 'primary'
  },
  btnText: {
    type: String,
    default: '请选择文件'
  },
  modelValue: {
    type: String,
    default: ''
  },
  width:{
    type: String,
    default: '178px'
  },
  height:{
    type: String,
    default: '178px'
  }
})
const emit = defineEmits(['update:modelValue'])

const fileList = ref<any>([])
const upload = ref<UploadInstance>()

watch(() => props.modelValue, async () => {
  if (props.modelValue) {
    let fileCodes: string[] = props.modelValue.split(',')
    let existCodes = fileList.value.map((f: any) => f.fileCode)
    let unExistFileCodes: any[] = fileCodes.filter((f: any) => existCodes.indexOf(f) == -1)
    if (unExistFileCodes.length > 0) {
      let resp = await $http.post('/api/nasServer/infos', qs.stringify({ fileCodes: unExistFileCodes.join(',') }))
      fileList.value.push(...resp.data.items)
    }

  }

}, { immediate: true, deep: true })

watch(fileList, () => {
  let fileCodes: Array<any> = []
  fileList.value.forEach((e: any) => {
    if (e.fileCode) {
      fileCodes.push(e.fileCode)
    } else {
      try {
        if (e.response.data.item.fileCode) {
          e['fileCode'] = e.response.data.item.fileCode
          fileCodes.push(e.fileCode)
        }
      } catch (e) {
        // console.error(e)
      }
    }
  })
  emit('update:modelValue', fileCodes.join((',')))
}, { deep: true })


const imageUrl = computed(()=>{
  if(fileList.value[0] && fileList.value[0].fileCode){
    return '/api/nasServer/access/' + fileList.value[0].fileCode
  }
  return ""
})
</script>

<template>
  <el-image  :src="imageUrl" :style="{width:width,height:height}" fit="cover" class="avatar" />
</template>

<style scoped>



</style>

<style >

</style>