import type DynamicFormConfig from '@/models/dynamic-form/DynamicFormConfig';

const modules = import.meta.glob('./**/*.json', { eager: true });
const models = new Map<string, string>();


const forms = []

for (const path in modules) {

  const module = modules[path].default;
  if (module) {
    forms.push(module);
  } else {
    console.error(`Invalid module at path: ${path}`, module);
  }
}

export default forms
