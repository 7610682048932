import { stringify } from 'qs'
import $http from '../../utils/DcHttp'
import DataModel from '../../models/dynamic-form/DataModel'
import type { AxiosRequestConfig } from 'axios'

export default class DynamicDataModel extends DataModel {

  entity: string

  addUrl: string
  addJsonUrl: string

  updateUrl: string

  detailUrl: string

  detailRefUrl: string

  listUrl: string

  deleteUrl: string

  batchDeleteUrl: string


  constructor(entity: string) {
    super()
    this.entity = entity
    this.addUrl = `/api/dynamicForm/add`
    this.updateUrl = `/api/dynamicForm/update`
    this.detailUrl = `/api/dynamicForm/detail`
    this.detailRefUrl = `/api/dynamicForm/detailRef`
    this.listUrl = `/api/dynamicForm/list`
    this.deleteUrl = `/api/dynamicForm/delete`
    this.batchDeleteUrl = `/api/dynamicForm/batchDelete`
  }

  async batchDelete(ids: {
                      ids?: any
                    } = {},
                    config: AxiosRequestConfig = {}
  ): Promise<any> {

    const params = stringify(Object.assign({ $entity: this.entity },
      ids
    ))

    return await $http.post(this.batchDeleteUrl, params, config)

  }

  async add(data = {},
            config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = stringify(Object.assign({ $entity: this.entity },
      data
    ))


    return await $http.post(this.addUrl, params, config)

  }

  async addJson(data = {},
                config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = Object.assign({ $entity: this.entity },
      data
    )

    return await $http.post(this.addJsonUrl, params, config)

  }

  async list(queryParams = {}
    ,
             pagerBean: {
               pageIndex?: any
               pageSize?: any
               isFirst?: boolean
               isLast?: boolean
               itemCount?: any
               btnNum?: any
               onPage?: boolean
               btnList?: any
               pageCount?: any
               items?: any
               orders?: any
               dynamicConditions?: any
             } = {},
             config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = stringify(Object.assign({ $entity: this.entity },
      queryParams
      ,
      pagerBean
    ))


    return await $http.post(this.listUrl, params, config)

  }

  async detail(data = {},
               config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = stringify(Object.assign({ $entity: this.entity },
      data
    ))


    return await $http.post(this.detailUrl, params, config)


  }

  async detailRef(data = {},
               config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = Object.assign({ $entity: this.entity }, data)


    return await $http.post(this.detailRefUrl, params, config)


  }

  async delete(data = {},
               config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = stringify(Object.assign({ $entity: this.entity },
      data
    ))


    return await $http.post(this.deleteUrl, params, config)

  }

  async update(data = {},
               config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = stringify(Object.assign({ $entity: this.entity },
      data
    ))


    return await $http.post(this.updateUrl, params, config)

  }

  async updateJson(data = {},
                   config: AxiosRequestConfig = {}
  ): Promise<any> {
    const params = Object.assign({ $entity: this.entity },
      data
    )


    return await $http.post(this.updateUrl, params, config)

  }


}