import axios from 'axios'
import jsonBig from 'json-bigint'
import { ElMessage } from 'element-plus'
import type { AxiosRequestConfig } from 'axios'

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showTips?: boolean;
}

const request = axios.create({
  timeout: 30000,
  // transformResponse 允许自定义原始的响应数据（字符串）
  transformResponse: [function(data) {
    try {
      // 如果转换成功则返回转换的数据结果
      const json = jsonBig({
        storeAsString: true
      })
      return json.parse(data)

    } catch (err) {
      // 如果转换失败，则包装为统一数据格式并返回
      return {
        data
      }
    }
  }]
})


// 响应拦截器
request.interceptors.response.use(
  response => {
    let config: CustomAxiosRequestConfig = response.config
    // 对响应数据做一些处理
    if (response.data.status === 200) {
      if (config.showTips) {
        ElMessage({
          message: response.data.desc,
          type: 'success'
        })
      }

      return response.data
    } else {

      if (response.data.status === 400) {
        return Promise.reject(response.data)
      } else {
        if (config.showTips) {
          ElMessage({
            message: response.data.desc,
            type: 'error'
          })
          return
        }
      }
      return Promise.reject(response.data)
    }
  },
  error => {
    // 对响应错误做一些处理
    if (error.response) {
      // 请求已发出，但服务器响应的状态码不在2xx范围内
      console.error('Error Response:', error.response.data)
      switch (error.response.status) {
        case 401:
          // 处理未授权的错误，比如跳转到登录页面
          break
        case 403:
          // 处理禁止访问的错误
          break
        case 404:
          // 处理资源未找到的错误
          break
        // 其他状态码的处理
        default:
          console.error('Unexpected Error:', error.response.status)
      }
    } else {
      // 请求未发出或其他错误
      console.error('Error Message:', error.message)
    }
    return Promise.reject(error)
  }
)


class DcHttp {

  async post(url: string, param: Object, config?: CustomAxiosRequestConfig) {
    return request.post(url, param, config)
  }

  async get(url: string, param: Object, config?: CustomAxiosRequestConfig) {
    return request.get(url, config)
  }

}
let $http = new DcHttp();
export default $http


