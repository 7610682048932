import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-user',
  name:'ShpUser',
  meta: {
    title: '用户管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpUserList',
      meta: {
        title: '用户列表'
      },
      component: ()=>{return import("@/views/shp-user/ShpUserList.vue")}
    },
    {
      path: 'add',
      name: 'ShpUserAdd',
      meta: {
        title: '用户新增'
      },
      component: ()=>{return import("@/views/shp-user/ShpUserAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpUserEdit',
      meta: {
        title: '用户编辑'
      },
      component: ()=>{return import("@/views/shp-user/ShpUserEdit.vue")}
    }
  ]
}
export default routers
