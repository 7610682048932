import qs from 'qs'

interface Attrs {
  placeholder: string,
  disabled: boolean,
  onChange: (event: Event) => void,
  onInput: (event: Event) => void
}

export class DcUtils {

  static array2Tree(array: Array, primaryKey, parentKey: String): Array<any> {
    const map = new Map<number, any>()
    const roots = []
    array.forEach(item => {
      item.children = [] // 初始化 children 属性
      map.set(item[primaryKey], item)
    })


    array.forEach(item => {
      if (!item[parentKey]) {
        roots.push(item) // 没有父节点，说明是根节点
      } else {

        const parent = map.get(item[parentKey])
        if (parent) {
          parent.children?.push(item) // 将当前项添加到父节点的 children 属性中
        }
      }
    })
    return roots
  }


  static parseDynamicCondQuery(str) {
    const data = {}
    str.split(',').forEach(item => {
      const [key, value] = item.split('@')
      data[key] = value
    })

    return data
  }

  static param2DynamicCondQuery(params: object) {
    const query = []
    for (const key in params) {
      query.push(key + '@' + encodeURIComponent(params[key]))
    }
    return query.length ? query.join(',') : ''
  }

  static toCamelCase(str: string) {
    return str.replace(/_([a-z])/g, function(match, letter) {
      return letter.toUpperCase()
    })
  }

  static mysqlType2DataType(type: string): string | undefined {

    enum DataType {
      VARCHAR = 'VARCHAR',
      INTEGER = 'INTEGER',
      DATE = 'DATE',
      BOOLEAN = 'BOOLEAN',
      TEXT = 'TEXT',
      NUMBER = 'NUMBER'
    }

    const mysqlDataTypeMapper: { [key: string]: string } = {
      'VARCHAR': DataType.VARCHAR,
      'INT': DataType.INTEGER,
      'BIGINT': DataType.INTEGER,
      'SMALLINT': DataType.INTEGER,
      'DECIMAL': DataType.NUMBER,
      'DATE': DataType.DATE,
      'DATETIME': DataType.DATE,
      'BOOLEAN': DataType.BOOLEAN,
      'TEXT': DataType.TEXT,
      'LONGTEXT': DataType.TEXT
    }

    return mysqlDataTypeMapper[type]
  }

  static qsParse(qsString) {
    let obj = qs.parse(qsString)
    let items = []
    for (let key in obj) {
      items.push({ name: key, value: obj[key] })
    }
    return items
  }

  static componentAttr(data: any, config: any): Attrs {
    const attrs = {
      placeholder: config.placeholder || config.name,
      disabled: config.isDisable === 'Y'
    }

    const formCompProps = typeof config['formCompProps'] === 'object' ? config['formCompProps'] : JSON.parse(config['formCompProps'] ? config['formCompProps'] : '{}')
    const diyCompProps = typeof config['diyCompProps'] === 'object' ? config['diyCompProps'] : JSON.parse(config['diyCompProps'] ? config['diyCompProps'] : '{}')

    const v = {}
    for (const key in data.row) {
      const k = `{{@row.${key}}}`
      v[k] = data.row[key]
    }

    for (const key in data.query) {
      const k = `{{@query.${key}}}`
      v[k] = data.query[key]
    }

    for (const key in data.queryParams) {
      const k = `{{@qp.${key}}}`
      v[k] = data.queryParams[key]
    }
    if (config['formCompProps']) {
      for (const key in formCompProps) {
        attrs[key] = v[formCompProps[key]] ? v[formCompProps[key]] : formCompProps[key]
      }
    }

    if (config['diyCompProps']) {
      for (const key in diyCompProps) {
        attrs[key] = v[diyCompProps[key]] ? v[diyCompProps[key]] : diyCompProps[key]
      }
    }
    return attrs

  }

  static formDefaultValue(defaultValue: string): string {
    if (defaultValue && (defaultValue.startsWith('KEY@') || defaultValue.startsWith('DATE@'))) {
      defaultValue = ''
    }
    return defaultValue
  }

}

