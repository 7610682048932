import type { RouteRecordRaw } from 'vue-router'
import DynamicListView from "../../views/dynamic-form/DynamicListView.vue"
import DynamicFormView from "../../views/dynamic-form/DynamicFormView.vue"
const routers: RouteRecordRaw = {
  path: '/menu',
  name:'menu',
  meta: {
    title: '表单菜单管理'
  },
  children: [
    {
      path: 'list',
      name: 'menu-list',
      meta: {
        title: '菜单列表'
      },
      props:{
        entity:'DC_MENU',
      },
      component: () => DynamicListView
    }
  ]
}
export default routers
