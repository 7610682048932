import Components from './src/components'
import routes from './src/router'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import dataForms from './data-form'
import dataLists from './data-list'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './src/assets/main.scss'
import $http from 'devcude-common/src/utils/DcHttp'

export default class DcCompSdk {

  static async initRouter(app, router) {


    const _routes = router.getRoutes()


    router.addRoute(routes)

    _routes.forEach((route) => {
      router.addRoute(route)
    })
    app.use(router)

  }

  static async init(app, router) {
    Components.register(app)
    DynamicFormUtils.installForm(dataForms)
    DynamicFormUtils.installList(dataLists)
    app.use(ElementPlus)
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    await this.initRouter(app, router)
  }
}