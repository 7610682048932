import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class DynamicFormCommonApi {

    static  async  diyComps(    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ))


  const resp = await $http.post("/api/dynamicFormCommon/diyComps",$_params,config);
  return resp;
  }
    static  async  formComps(    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ))


  const resp = await $http.post("/api/dynamicFormCommon/formComps",$_params,config);
  return resp;
  }

}