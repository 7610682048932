import type { RouteRecordRaw } from 'vue-router'
import DynamicListView from "../../views/dynamic-form/DynamicListView.vue"
const routers: RouteRecordRaw = {
  path: '/datasource',
  name:'datasource',
  meta: {
    title: '数据源管理'
  },
  children: [
    {
      path: 'list',
      name: 'datasource-list',
      meta: {
        title: '数据源列表'
      },
      props:{
        entity:'DC_DATASOURCE',
      },
      component: () => DynamicListView
    }
  ]
}
export default routers
