<template>
  <DcLayout :config="config">
      <router-view :key="$route.fullPath"></router-view>
  </DcLayout>
</template>
<script lang="ts" setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import LayoutConfig from '../types/models/layout/LayoutConfig'
import DropdownItemConfig from '../types/models/layout/DropdownItemConfig'
import _ from 'lodash'
import DropdownConfig from '../types/models/layout/DropdownConfig'
import AuthCompCommonApi from 'dc-comp-sdk/api/AuthCompCommonApi'
import DcLayout from '../components/layout/DcLayout.vue'
const route = useRoute();
const config = ref(new LayoutConfig())
const userDropdownConfig = new DropdownConfig();
if(route?.query?.$menuFlag){
  localStorage.setItem('MENU_FLAG',route.query.$menuFlag);
}
let flag = localStorage.getItem('MENU_FLAG');
AuthCompCommonApi.userMenus({flag:flag}).then((resp)=>{
  console.log(resp)
  config.value.menuConfig = resp.data.items;
});
userDropdownConfig.items =  [
  _.defaultsDeep({ title: '个人中心', icon: 'user' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: '用户设置', icon: 'setting' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: '退出', icon: 'user', divided: true }, new DropdownItemConfig())
];
const langDropdownConfig = new DropdownConfig();
langDropdownConfig.items = [
  _.defaultsDeep({ title: '简体中文' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: '繁體中文' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: 'English' }, new DropdownItemConfig())
];
config.value.userDropdownConfig = userDropdownConfig

config.value.langDropdownConfig = langDropdownConfig

</script>
