<script setup lang="ts">
import { ElUpload, type UploadFile, type UploadFiles, type UploadInstance } from 'element-plus'
import { computed, ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import qs from 'qs'

const props = defineProps({
  btnType: {
    type: String,
    default: 'primary'
  },
  btnText: {
    type: String,
    default: '请选择文件'
  },
  modelValue: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])

const fileList = ref<any>([])
const upload = ref<UploadInstance>()

watch(() => props.modelValue, async () => {
  if (props.modelValue) {
    let fileCodes: string[] = props.modelValue.split(',')
    let existCodes = fileList.value.map((f: any) => f.fileCode)
    let unExistFileCodes: any[] = fileCodes.filter((f: any) => existCodes.indexOf(f) == -1)
    if (unExistFileCodes.length > 0) {
      let resp = await $http.post('/api/nasServer/infos', qs.stringify({ fileCodes: unExistFileCodes.join(',') }))
      fileList.value.push(...resp.data.items)
    }

  }

}, { immediate: true, deep: true })

watch(fileList, () => {
  let fileCodes: Array<any> = []
  fileList.value.forEach((e: any) => {
    if (e.fileCode) {
      fileCodes.push(e.fileCode)
    } else {
      try {
        if (e.response.data.item.fileCode) {
          e['fileCode'] = e.response.data.item.fileCode
          fileCodes.push(e.fileCode)
        }
      } catch (e) {
        // console.error(e)
      }
    }
  })
  emit('update:modelValue', fileCodes.join((',')))
}, { deep: true })


</script>

<template>
  <el-upload
    class="w-full"
    ref="upload"
    v-model:file-list="fileList"
    action="/api/nasServer/upload"
    :limit="1"
  >
    <template #trigger>
      <el-button :type="btnType" size="large">{{ btnText }}</el-button>
    </template>
  </el-upload>
</template>

<style scoped>

</style>