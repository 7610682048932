import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_BUS_NUM_TEMPLE"
const routers: RouteRecordRaw = {
  path: '/bsnm',
  name:'bsnm',
  meta: {
    title: '业务编号管理'
  },
  children: [
    {
      path: 'list',
      name: 'bsnm-list',
      meta: {
        title: '编号规则列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    },
  ]
}
export default routers
