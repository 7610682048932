import DynamicListModel from '../models/dynamic-form/DynamicListModel'
import DynamicFormModel from '../models/dynamic-form/DynamicFormModel'
import DynamicTableFormModel from '../models/dynamic-form/DynamicTableFormModel'
import DynamicDataModel from '../models/dynamic-form/DynamicDataModel'
import $http from '../utils/DcHttp'
import qs from 'qs'

export default class DynamicFormUtils {

  static cache = {}

  static dataForm = {}

  static dataList = {}


  static installForm(forms:Array<any>) {
    forms.forEach(form => {
      DynamicFormUtils.dataForm[`${form['entityFlag']}@${form['flag']}`] = form
    })
  }

  static installList(lists:Array<any>){
    lists.forEach(list => {
      DynamicFormUtils.dataList[`${list['entityFlag']}@${list['flag']}`] = list
    })
  }

  static async getTableFormModel(entity: string, flag: string = 'FORM_MAIN'): Promise<DynamicTableFormModel | null> {

    // let  config = DynamicFormUtils.dataForm[`${entity}@${flag}`];
    let  config = null;

    try {
      let resp = await $http.post("/api/dynamicFormUtils/dataFormConfig",qs.stringify({entity:entity,flag:flag}));
      config = resp.data.item;
    }catch (e){

    }

    if(!config){
      return null;
    }

    const dataModel = new DynamicDataModel(entity)
    DynamicFormUtils.configModelUrl(dataModel, config);
    const model = new DynamicTableFormModel(dataModel)
    model.config = config
    return model


  }

  static configModelUrl(dataModel:DynamicDataModel,config:any){
     if(config.addUrl){
       dataModel.addUrl = config.addUrl;
     }

    if(config.updateUrl){
      dataModel.updateUrl = config.updateUrl;
    }

    if(config.deleteUrl){
      dataModel.deleteUrl = config.deleteUrl;
    }

    if(config.detailUrl){
      dataModel.detailUrl = config.detailUrl;
    }

    if(config.listUrl){
      dataModel.listUrl = config.listUrl;
    }

    if(config.batchDeleteUrl){
      dataModel.batchDeleteUrl = config.batchDeleteUrl;
    }
  }


  static async getListModel(entity: string, flag: string = 'LIST_MAIN'): Promise<DynamicListModel | null> {
    console.log(`DynamicListModel:${entity}@${flag}`,);
    // const  config = DynamicFormUtils.dataList[`${entity}@${flag}`];
    let  config = null;

    try {
      let resp = await $http.post("/api/dynamicFormUtils/dataListConfig",qs.stringify({entity:entity,flag:flag}));
      config = resp.data.item;
    }catch (e){

    }
    if(!config){
      return null;
    }
    const dataModel = new DynamicDataModel(entity)
    DynamicFormUtils.configModelUrl(dataModel, config);

    const model = new DynamicListModel(dataModel)
    model.config = config
    return model

  }



  static async getFormModel(entity: string, flag: string = 'FORM_MAIN'): Promise<DynamicFormModel | null> {

    console.log(`正在加载DynamicTableFormModel:${entity}@${flag}`,);
    // let  config = DynamicFormUtils.dataForm[`${entity}@${flag}`];
    let  config = null;

    try {
      let resp = await $http.post("/api/dynamicFormUtils/dataFormConfig",qs.stringify({entity:entity,flag:flag}));
      config = resp.data.item;
    }catch (e){

    }

    if(!config){
      return null;
    }

    const dataModel = new DynamicDataModel(entity)
    DynamicFormUtils.configModelUrl(dataModel, config);

    const model = new DynamicFormModel(dataModel)
    model.config = config
    return model

  }

}