import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/bogo-product',
  name:'BogoProduct',
  meta: {
    title: '满赠送管理'
  },
  children: [
    {
      path: 'list',
      name: 'BogoProductList',
      meta: {
        title: '满赠送列表'
      },
      component: ()=>{return import("@/views/bogo-product/BoGoProductList.vue")}
    }
  ]
}
export default routers
