<template>
  <div v-if="!model?.config.routeComp" class="bg-[#fff] p-[24px] pt-[32px] pb-[32px] flex flex-col items-center h-full !justify-start">
    <el-form size="large" label-width="auto" class="bg-[#fff] !min-w-[500px]">

      <template v-for="(item,index) in model?.config.fields" :key="index">

        <slot :name="item['dataEntityFieldFlag']" :row="formData" :item="item">
          <el-form-item v-if="item['showStatus'] == 'Y'" :error="errorTips[item['dataEntityFieldFlag']]"
                        :label="item['name']" :required="item['isRequire'] == 'Y'">
            <el-input v-if="!item['formComp']"
                      :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                      :disabled="item['isDisable'] == 'Y'" v-model="formData[item['dataEntityFieldFlag']]"></el-input>
            <dc-dynamic-comp
              v-else v-bind:is="item['formComp'] as string"
              v-model="formData[item['dataEntityFieldFlag']]"
              v-bind="generateAttrs(formData,item)"
            ></dc-dynamic-comp>
          </el-form-item>
        </slot>
      </template>


      <div class="flex justify-center">
        <el-button @click="handleSave" type="primary" size="large">保存</el-button>
        <el-button @click="handleReset" size="large">重置</el-button>
      </div>
    </el-form>
  </div>
  <DcDynamicComp v-else :is="model?.config.routeComp"></DcDynamicComp>
  <entity-design-dialog v-if="model && model.config && model.config.entityId" :entityId="model.config.entityId" init-tab="formDesign"></entity-design-dialog>

</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import type DcResponse from 'devcude-common/src/models/DcResponse'
import type DynamicFormModel from 'devcude-common/src/models/dynamic-form/DynamicFormModel'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import DcDynamicComp from '../form/DcDynamicComp.vue'
import EntityDesignDialog from '../biz/EntityDesignDialog.vue'

const formData = ref({})
const props = defineProps(['initData', 'handleFinish', 'saveParams', 'entity', 'dataFormFlag', 'queryParams'])
const model = ref<DynamicFormModel>()

const route = useRoute()
const router = useRouter()
const errorTips = ref({})

interface Attrs {
  placeholder: string,
  disabled: boolean,
  onChange: (event: Event) => void,
  onInput: (event: Event) => void
}

function generateAttrs(row: any, item: any): Attrs {

  let attrs = {
    placeholder: item.placeholder || item.name,
    disabled: item.isDisable === 'Y'
  }

  const formCompProps = typeof item['formCompProps'] === 'object' ? item['formCompProps'] : JSON.parse(item['formCompProps'] ? item['formCompProps'] : '{}')
  console.log(formCompProps)
  let v = {}
  for (let key in row) {
    let k = `{{@row.${key}}}`
    v[k] = row[key]
  }

  for (let key in route.query) {
    let k = `{{@query.${key}}}`
    v[k] = route.query[key]
  }



  for (let key in props.queryParams) {
    let k = `{{@qp.${key}}}`
    v[k] = props.queryParams[key]
  }


  if (item['formCompProps']) {
    for (let key in formCompProps) {
      attrs[key] = v[formCompProps[key]] ? v[formCompProps[key]] : formCompProps[key]
    }
  }

  return attrs

}

let v = null
loadConfig()

async function loadConfig() {
  if (props.entity && props.dataFormFlag) {
    const e = await DynamicFormUtils.getFormModel(props.entity, props.dataFormFlag)
    model.value = e
    model.value?.config.fields.forEach(item => {
      let defaultValue = item['defaultValue']

      if (defaultValue && (defaultValue.startsWith('KEY@') || defaultValue.startsWith('DATE@'))) {
        defaultValue = ''
      }
      formData.value[item['dataEntityFieldFlag']] = defaultValue
      errorTips.value[item['dataEntityFieldFlag']] = ''
    })
    if (props.initData) {
      loadData()
    }
    for (let key in props.saveParams) {
      if (props.saveParams[key]) formData.value[key] = props.saveParams[key]
    }
  }
}

async function loadData() {
  const resp = await model.value?.detail(props.saveParams)
  if (resp?.status == 200) {
    v = resp.data.item
    handleReset()
  }
}

async function handleSave() {

  console.log(model.value)
  let fd = Object.assign({
    $form: model.value?.config.flag
  }, formData.value, props.saveParams ? props.saveParams : {})

  for (let key in fd) {
    if (!fd[key]) delete fd[key]
  }

  //校验必填
  let validSuc = true
  for (let i = 0; i < model.value?.config.fields.length; i++) {
    const field = model.value?.config.fields[i]
    if (field.isRequire === 'Y' && field.show === 'Y' && !fd[field.dataEntityFieldFlag]) {
      errorTips.value[field['dataEntityFieldFlag']] = '不能为空'
      validSuc = false
    }
  }
  if (!validSuc) {
    return
  }

  try {
    if (!v) {
      await model.value?.cfg({ showTips: true }).add(fd)
    } else {
      await model.value?.cfg({ showTips: true }).update(fd)
    }
    if (props.handleFinish instanceof Function) {
      props.handleFinish()
    } else {
      router.back()
    }
  } catch (e) {
    let errResp = e as DcResponse
    if (errResp.status == 400) {
      console.log(errResp.data.errorFields)
      errResp.data.errorFields.forEach((e) => {
        errorTips.value[e.name] = e.message
      })
    }
  }


}

function handleReset() {
  if (v) {
    for (let key in formData.value) {
      formData.value[key] = v[key]
    }
  } else {
    for (let item in model.value?.dataModel) {
      formData.value[model.value?.dataModel[item].name] = ''
    }
  }

}

watch(() => props.initData, () => {
  console.log('ddddd', props.initData)
  if (props.initData) {
    loadData()
  }
}, { deep: true, immediate: true })


</script>
