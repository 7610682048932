<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps(['entity'])
import { useRoute } from 'vue-router'

const route = useRoute()
let modelName = route.params.model?.toString()
if (!modelName) {
  modelName = props.entity
}




</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <common-list    :entity="modelName"  ></common-list>
  </div>
</template>

<style scoped>

</style>