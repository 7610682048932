<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps(['entity','flag'])
import { useRoute } from 'vue-router'
import CommonTreeList from '../../dynamic-form/CommonTreeList.vue'

const route = useRoute()
let entity = route.params._entity?.toString()
if (!entity) {
  entity = props.entity
}

let flag = route.params._flag?.toString()
if (!flag) {
  flag = props.flag
}




</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <common-tree-list   :entity="entity" :data-list-flag="flag"></common-tree-list>
  </div>
</template>

<style scoped>

</style>