import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-vip-discount',
  name:'ShpVipDiscount',
  meta: {
    title: '会员折扣管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpVipDiscountList',
      meta: {
        title: '会员折扣列表'
      },
      component: ()=>{return import("@/views/shp-vip-discount/ShpVipDiscountList.vue")}
    },
    {
      path: 'add',
      name: 'ShpVipDiscountAdd',
      meta: {
        title: '会员折扣新增'
      },
      component: ()=>{return import("@/views/shp-vip-discount/ShpVipDiscountAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpVipDiscountEdit',
      meta: {
        title: '会员折扣编辑'
      },
      component: ()=>{return import("@/views/shp-vip-discount/ShpVipDiscountEdit.vue")}
    }
  ]
}
export default routers
