<script setup lang="ts">
import { ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import qs from 'qs'
import $api from '../config/api'
import DcDictSelect from 'dc-comp-sdk/src/components/form/DcDictSelect.vue'

let props = defineProps({
  name: {
    type: String,
    default: ''
  }
})

const r = ref('')
const v = ref('')
const formData = {
  type: '',
  dates: '',
  dateList: [],
  earliestShipTime: '',
  latestShipTime: ''
}
const fd = ref(formData)

watch(fd, () => {
  v.value = JSON.stringify(fd.value)
}, { deep: true })

async function handleLoadData() {
  let resp = await $http.post($api.shpSetting.info, qs.stringify({ name: props.name }))
  if (resp.data.item.value) {
    if (resp.data.item.value) fd.value = JSON.parse(resp.data.item.value)
    r.value = resp.data.item.value
    v.value = resp.data.item.value
  }

}

async function handleSave() {
  if (r.value == v.value) {
    return
  }
  try {
    let resp = await $http.post($api.shpSetting.save, qs.stringify({
      name: props.name,
      value: JSON.stringify(fd.value)
    }))
    r.value = v.value
  } catch (e) {

  }
}


handleLoadData()


</script>

<template>
  <div class="border p-[24px] w-[1370px]">
    <el-form>
      <el-row>

        <el-col :span="6">
          <el-form-item label="发货日期类型" prop="skipRule.type">
            <DcDictSelect v-model="fd.type" dict-type="DCSHP_SHP_DATE_TYPE"></DcDictSelect>
          </el-form-item>
        </el-col>

      </el-row>


      <el-row v-if="fd.type === 'SPECIFY_DATE'" class="mt-[24px]">
        <el-col :span="6">
          <el-form-item label="指定日期" prop="dates">
            <el-date-picker v-model="fd.dateList" value-format="YYYY-MM-DD" type="dates"
                            placeholder="请选择日期"></el-date-picker>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row gutter="24" v-if="fd.type === 'NEXT'" class="mt-[24px]">
        <el-col :span="6">
          <el-form-item label="最早发货" prop="earliestShipTime">
            <el-input placeholder="最早发货时间" v-model="fd.earliestShipTime"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最迟发货" prop="latestShipTime">
            <el-input placeholder="最早发货时间" v-model="fd.latestShipTime"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div class="mt-[12px]">
      <el-button @click="handleSave" type="primary" :disabled="r == v">保存</el-button>
    </div>
  </div>
</template>

<style scoped>

</style>