<script setup lang="ts">
import DcSubMenu from './DcSubMenu.vue'
const props = defineProps(['isCollapse','menu'])
console.log("ddd",props.menu)
</script>

<template>
  <el-menu
    :collapse="isCollapse"
    active-text-color="#fff"
    :collapse-transition="false"
    background-color="#191a23"
    text-color="hsla(0,0%,100%,.7)"
    class="!border-r-0"
    :router="true"
  >
    <template v-for="item in menu" :key="item.id">
      <el-menu-item :index="item.url ? item.url : item.id"  v-if="!item.children" >
        <span>{{item.name}}</span>
      </el-menu-item>

      <dc-sub-menu v-else :menu="item"></dc-sub-menu>

    </template>


  </el-menu>
</template>

<style scoped>

</style>