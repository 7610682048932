import type { RouteRecordRaw } from 'vue-router'
const entity = "TEST_EMP_INFO"
const routers: RouteRecordRaw = {
  path: '/emp-info',
  name:'emp-info',
  meta: {
    title: '员工信息管理'
  },
  children: [

    {
      path: 'list',
      name: 'emp-list',
      meta: {
        title: '列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }
  ]
}
export default routers
